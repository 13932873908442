import {html} from '../../../../common/tpl/tpl';
import {classes, repeat, dataAttr} from '../../../../common/tpl/directives';
import imgTpl from '../media/img';
import icon from '../media/icon';

import paginationTpl from '../pagination';

export default (blueprint) => {
	const RemoteApiRepository = blueprint.remoteApiRepository;
	const items = blueprint.items;
	const initial = blueprint.initial;
	const loading = blueprint.loading;
	const layout = blueprint.layout;
	const pagination = blueprint.pagination;
	const thumbUrl = RemoteApiRepository.getThumbUrl();
	const imgOptions = {
		async: true,
		autoload: 'lazy'
	};

	const result = () => html`
		${repeat(items, (item) => {
			const assetId = item.asset.id;
			const imgSrc = thumbUrl + '?assetId=' + assetId;
			return html`
			<li class=${classes('resultList__item')}>
				<div class=${classes('resultList__imgWrapper')}>
					${imgTpl(imgSrc, imgOptions)}
				</div>
				<div class=${classes('resultList__content')}>
					<h2 class=${classes('resultList__text', 'resultList__text--highlight')}>${item.name}</h2>
					<div class=${classes('resultList__details')}>
						<div class=${classes('resultList__detail')}>
							<p class=${classes('resultList__text')}>${item.titles.join(', ')}, ${item.date}</p>
							<!--<p class=${classes('resultList__text')}>${item.classification}</p>-->
							<p class=${classes('resultList__text')}>${item.material}</p>
						</div>
						<div class=${classes('resultList__detail', 'resultList__detail--dimensions')}>
							${repeat(item.dimensions, (dimension) => html`
								<p class=${classes('resultList__text')}>${dimension}</p>
							`)}
							<p class=${classes('resultList__text')}>${item.id}</p>
						</div>
					</div>
				</div>
			</li>
		`;
	})}`;

	const emptyResult = () => html`
		<li class=${classes('resultList__item resultList__item--emptyResult')}>
			<h2>${!initial ? 'Keine einträge gefunden' : ''}</h2>
		</li>

	`;


	const stateTpl = () => {
		let tpl = null;
		if (!initial) {
			if (items.length > 0) {
				tpl = result(items);
			} else {
				if (!loading) {
					tpl = emptyResult();
				}
			}
		}
		return tpl;
	};

	return html`
		<a class=${classes('anchor')} id="artworksResultList"></a>
		<div class=${classes('resultList', layout === 'cards' ? 'js-layout-alternate' : '')} data=${dataAttr({resultList: ''})}>
			<span class=${classes('resultList__loading', loading ? 'js-visible' : '')}>
				<span class=${classes('resultList__icon')}>${icon('loader')}</span>
			</span>
			<ul class=${classes('resultList__list')}>
				${stateTpl()}
			</ul>
			${paginationTpl(pagination)}
		</div>
	`;
};

import 'intersection-observer';
import svg4everybody from 'svg4everybody';
import picturefill from 'picturefill';
import App from '../../common/app/app';


class MainApp extends App {

	injectHashScroller(hashScroller) {
		this.hashScroller = hashScroller;
	}


	init() {
		svg4everybody();
		picturefill();
		this.contexts.setDefaultContext('default', false);
		return super.init();
	}


	execute() {
		this.contexts.getContext('default').activate();
		const hash = location.hash;
		if (hash.length) {
			this.events.trigger(document.body, 'history:hashchange', {hash: hash.substr(1)});
		}
	}

}

export default MainApp;

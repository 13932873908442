import DynamicTemplateSection from '../../template-controller/dynamic-template-section';


/**
 * The markup of a section is loaded in the DOM only when the section is the current one,
 * so all the event listeners need to be delegated and node queries cannot be static
 *
 * @class DynamicSectionDefaultContent
 * @extends {DynamicTemplateSection}
 */
class DynamicSectionDefaultContent extends DynamicTemplateSection {

}


export default DynamicSectionDefaultContent;

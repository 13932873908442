export default async () => {
	const options = [
		{
			label: 'Künstler:innen A-Z',
			value: 'alphabetical'
		},
		// {
		// 	label: 'Erwerbsdatum',
		// 	value: 'dateOfAcquisition'
		// },
		{
			label: 'Werkdatierung',
			value: 'dateOfCreation'
		}
	];

	const blueprint = {
		name: 'sortField',
		inputName: 'sortFieldInput',
		id: 'sort',
		type: 'radio',
		value: 'alphabetical',
		attributes: {},
		classes: [],
		modifiers: [],
		data: {},
		options: options,
		disabled: false,
		required: false,
		hidden: false,
		readonly: false
	};

	return blueprint;
};

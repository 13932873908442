import {html} from '../../../../../common/tpl/tpl';
import {classes, dataAttr, attr, once, repeat} from '../../../../../common/tpl/directives';
import labelTpl from './label';
import icon from '../../media/icon';


export default (field) => html`
	${labelTpl(field)}
	<div class=${classes('field__inputWrapper')}>
		<select
			name=${once(field.inputName)}
			id=${once(field.id)}
			class=${classes('field__input', 'field__input--select')}
			data=${dataAttr('data' in field ? field.data : {})}
			attr=${attr(Object.assign({}, field.attributes))}>

			${repeat(field.options ? field.options : [], (option, index) => {
				const label = option.label;
				const value = option.value;
				const data = 'data' in option ? option.data : {};
				const attributes = Object.assign({}, 'attributes' in option ? option.attributes : {});
				attributes['?selected'] = (String(field.value) === String(value));
				return html`
					<option
						class=${classes('field__selectOption')}
						data=${dataAttr(data)}
						attr=${attr(Object.assign({}, attributes, {value: value}))}>
						${label}
					</option>`;
			})}
		</select>
		<span class=${classes('field__selectIcon')}>${icon('16-dropdown')}</span>
	</div>`;

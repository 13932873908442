import {spread} from '@open-wc/lit-helpers';

function isObj(value) {
	const type = typeof value;
	return type === 'function' || type === 'object' && !!value;
}

function encodeValue(value) {
	if (Array.isArray(value) || isObj(value)) {
		return JSON.stringify(value);
	}
	switch (value) {
		case true:	return 'true';
		case false:	return 'false';
		case null:	return 'null';
		default:	return value + '';
	}
}

// const dataAttr = (index, inStrings, inValues, outStrings, outValues) => {
// 	const str = inStrings[index];
// 	const pattern = ' ' + dataAttr.attributeName + '=';
// 	const pos = str.lastIndexOf(pattern);
// 	if (index < inValues.length && pos === str.length - pattern.length) {
// 		let first = true;
// 		for (const name in inValues[index]) {
// 			if (inValues[index].hasOwnProperty(name)) {
// 				const newStr = (first ? str.substr(0, pos) + ' ' : ' ');
// 				const newName = 'data-' + dataAttr.ns + name.replace(/([a-z0-9]+)([A-Z]+)/g, '$1-$2').toLowerCase();
// 				const newValue = encodeValue(inValues[index][name]);
// 				outStrings.push(newStr + newName + '=');
// 				outValues.push(newValue);
// 				first = false;
// 			}
// 		}
// 		return true;
// 	}
// 	return false;
// };

// dataAttr.attributeName = '$data';
// dataAttr.ns = '';

const dataAttr = (data) => {
	const newData = {};
	for (const name in data) {
		if (data.hasOwnProperty(name)) {
			const value = data[name];
			if (value !== undefined) {
				const newName = 'data-' + dataAttr.ns + name.replace(/([a-z0-9]+)([A-Z]+)/g, '$1-$2').toLowerCase();
				newData[newName] = encodeValue(value);
			}
		}
	}
	return spread(newData);
};

dataAttr.ns = '';


export default dataAttr;

import {html} from '../../../../../common/tpl/tpl';
import {classes, dataAttr, attr, once, live} from '../../../../../common/tpl/directives';
import labelTpl from './label';

export default (field) => html`
	${labelTpl(field)}
	<div class=${classes('field__inputWrapper')}>
		<input type="number"
			name=${once(field.inputName)}
			id=${once(field.id)}
			class=${classes('field__input', 'field__input--number')}
			data=${dataAttr('data' in field ? field.data : {})}
			.value=${field.liveUpdate ? live(field.value) : field.value}
			attr=${attr(Object.assign({}, field.attributes, {'?readonly': field.readonly, '.disabled': field.disabled}))} />
	</div>`;

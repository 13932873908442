import {html, nothing} from '../../../common/tpl/tpl';
import {classes, dataAttr, attr, repeat} from '../../../common/tpl/directives';
import icon from './media/icon';


export default (button, attributes = {}, data = {}) => {
	let buttonClasses = ['button'];
	if ('modifiers' in button && Array.isArray(button.modifiers)) {
		buttonClasses = buttonClasses.concat(button.modifiers.map((modifier) => 'button--' + modifier));
	}
	if ('classes' in button) {
		buttonClasses = buttonClasses.concat(button.classes);
	}
	attributes = Object.assign({}, attributes);
	data = Object.assign({}, ('data' in button ? button.data : {}), data);
	// if ('title' in attributes) {
	// 	attributes.title = this.text.print(attributes.title);
	// }
	const tagName = ('href' in attributes ? 'a' : 'button');
	if (tagName === 'button' && !('type' in attributes)) {
		attributes.type = 'button';
	}
	if (!('label' in button)) {
		buttonClasses.push('button--onlyIcon');
	} else if(!('preIcon' in button) && !('postIcon' in button)) {
		buttonClasses.push('button--onlyLabel');
	}

	const contentItems = [
		{key: 'preIcon', baseClass: 'button__icon', isIcon: true},
		{key: 'label', baseClass: 'button__label'},
		{key: 'postIcon', baseClass: 'button__icon', isIcon: true}
	];

	const content = html`
		${repeat(contentItems, (item) => {
			if (item.key in button) {
				const property = (Array.isArray(button[item.key]) ?
					button[item.key] :
					[{value: button[item.key], modifiers: [], data: {}}]
				);
				return property.map((entry) => html`
					<span class=${classes([item.baseClass].concat((entry.modifiers || []).map((modifier) => item.baseClass + '--' + modifier)))} data=${dataAttr(entry.data || {})}>
						${item.isIcon ? icon(entry.value) : entry.value}
					</span>`);
			}
			return nothing;
		})}
	`;

	if ('href' in attributes) {
		return html `<a class=${classes(buttonClasses)} data=${dataAttr(data)} attr=${attr(attributes)}>${content}</a>`;
	}
	if (!('type' in attributes)) {
		attributes.type = 'button';
	}
	return html `<button class=${classes(buttonClasses)} data=${dataAttr(data)} attr=${attr(attributes)}>${content}</button>`;
};

import {html} from '../../../../../common/tpl/tpl';
import {classes, repeat} from '../../../../../common/tpl/directives';
import optionsTpl from './radio-options';


export default (field, groups) => html`
	<ul class=${classes('field__inputGroups')}>
		${repeat(groups ? groups : [], (group, index) => html`
			<li class=${classes('field__inputGroup')}>
				<p class=${classes('field__inputGroupLabel')}>${group.label}</p>
				${optionsTpl(field, group.options)}
			</li>
		`)}
	</ul>`;

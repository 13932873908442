export default async () => {
	const options = [
		{
			label: 'Liste',
			value: 'list',

		},
		{
			label: 'Kachel',
			value: 'cards'
		}
	];

	const blueprint = {
		name: 'layoutField',
		inputName: 'layoutFieldInput',
		id: 'layout',
		type: 'radio',
		value: 'list',
		attributes: {},
		classes: [],
		modifiers: [],
		data: {},
		options: options,
		disabled: false,
		required: false,
		hidden: false,
		readonly: false
	};

	return blueprint;
};

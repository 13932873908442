import domMixin from '../../common/dom/dom-mixin';
import listenerMixin from '../../common/events/listener-mixin';


/**
 * The markup of a section is loaded in the DOM only when the section is the current one,
 * so all the event listeners need to be delegated and node queries cannot be static
 *
 * @class DynamicTemplateSection
 *
 */
class DynamicTemplateSection extends listenerMixin(domMixin()) {

	constructor({
		dynamicTemplate,
	}) {
		super();
		this.dynamicTemplate = dynamicTemplate;
		this.dynamicTemplateElement = this.dynamicTemplate.getElement();
		this.active = false;
	}


	init() {
		this.initListeners();
	}

	destroy() {
		this.destroyListeners();
	}


	canActivate() {
		return !this.active;
	}


	async activate() {
		if (!this.active) {
			this.active = true;
			return true;
		}
		return false;
	}


	async deactivate() {
		if (this.active) {
			this.active = false;
			return true;
		}
		return false;
	}

}


export default DynamicTemplateSection;

import {html} from '../../../../../common/tpl/tpl';
// import {classes, dataAttr, attr, once} from '../../../../common/tpl/directives';
import labelTpl from './label';
import fieldsTpl from '../fields';


export default (field) => html`
	${labelTpl(field)}
	${fieldsTpl(field.fields, field.value, field)}
`;


const iconInfo = (id, file) => {
	if (!iconInfo.loader) {
		throw Error('icon files loader not defined');
	}

	const url = (file ? iconInfo.loader.getFileUrl(file) : '') + '#' + id;
	const info = iconInfo.loader.getIconInfo(id, file);
	info.url = url;
	return info;
};

iconInfo.loader = null;

export default iconInfo;

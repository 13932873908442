import arrayMergeRecursive from 'locutus/php/array/array_merge_recursive';
import deepClone from '../utils/deep-clone';


const img = (path, options = {}) => {
	const imgOptions = img.options;
	const imgDefaultOptions = deepClone(imgOptions.imgDefaultOptions);

	options = arrayMergeRecursive(imgDefaultOptions, options);
	options.imgData.component = imgOptions.component;
	options.imgData.autoload = options.autoload;

	const srcsets = options.srcsets ? options.srcsets : imgOptions.srcsets;
	const sizes = (options.sizes in imgOptions.sizes ? imgOptions.sizes[options.sizes] : imgOptions.sizes[imgDefaultOptions.sizes]);

	const imgInfo = (path in imgOptions.imagesInfo ? imgOptions.imagesInfo[path] : {});
	const style = options.style + ('width' in imgInfo && +imgInfo.width ? `--w: ${imgInfo.width}; --h: ${imgInfo.height}; --heightRatioPerc: ${Math.round(imgInfo.height / imgInfo.width * 1000000) / 10000}%;` : '');
	const timestamp = imgInfo.datetime || '00000000000000';
	const basePath = imgOptions.basePath;
	const [baseName, ext] = path.split(/(.*)(\.[^.]*)$/g).slice(1, 3);
	const fallbackSize = (options.fallbackSize ? options.fallbackSize : imgOptions.fallbackSize);

	const src = ext ? imgOptions.src : path;
	let srcset = '';
	if (ext) {
		if (ext.toLowerCase() === '.svg') {
			srcset = `${imgOptions.baseUrl}${basePath}/${baseName}.${timestamp}${ext}`;
			if (options.fallback) {
				options.fallbackUrl = srcset;
			}
		} else {
			srcset = srcsets.map((size) => `${imgOptions.baseThumbUrl}${basePath}/${baseName}-${timestamp}.${size}-0-${imgOptions.imageQuality}${ext} ${size}w`).join(', ');
			if (options.fallback) {
				options.fallbackUrl = `${imgOptions.baseThumbUrl}${basePath}/${baseName}-${timestamp}.${fallbackSize}-0-${imgOptions.imageQuality}${ext}`;
			}
		}
	}

	return {srcset: srcset, sizes: sizes, src: src, style: style, options: options};
};

img.options = {
	component: 'AsyncPicture',
	imgDefaultOptions: {
		async: true,
		attributes: {},
		blockClass: 'image',
		modifiers: [],
		classes: [],
		data: {},
		alt: null,
		autoload: 'lazy',
		imgAttributes: {},
		imgClasses: ['image__img'],
		imgData: {},
		link: null,
		linkClasses: [],
		linkAttributes: {},
		linkData: {},
		caption: null,
		captionClasses: [],
		captionData: {},
		imgWrapper: false,
		imgWrapperClasses: [],
		onlyImg: false,
		style: '',
		srcsets: null,
		fallback: false,
		fallbackSize: null,
		sizes: 'default'
	},
	src: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
	srcsets: [320, 512, 768, 1024, 1280, 1400, 1600, 2048, 2500],
	sizes: {
		default: '100vw',
	},
	fallbackSize: 1280,
	imagesInfo: {},
	imageQuality: 85,
	baseUrl: '',
	basePath: '/assets',
	baseThumbUrl: ''

};


export default img;


import ContentOverlayController from './content-overlay-controller';
import SearchController from './search-controller';
import ModalController from './modal-controller';
import UserGeneratedContent from './user-generated-content';
import UserGeneratedTable from './user-generated-table';
import UserGeneratedZoomImage from './user-generated-zoom-image';


export default (di) => {
	di
		.setType({type: UserGeneratedContent, name: 'UserGeneratedContent', parent: 'PageComponent'})
		.setType({type: UserGeneratedTable, name: 'UserGeneratedTable', parent: 'PageComponent'})
		.setType({type: UserGeneratedZoomImage, name: 'UserGeneratedZoomImage', parent: 'PageComponent'})
		.setType({type: ContentOverlayController, name: 'ContentOverlayController', parent: 'PageComponent'})
		.setType({type: SearchController, name: 'SearchController', parent: 'PageComponent'})
		.setType({type: ModalController, name: 'ModalController', parent: 'PageComponent'})
	;
};

import {html} from '../../../../../common/tpl/tpl';
import {classes, dataAttr, attr, once} from '../../../../../common/tpl/directives';
import labelTpl from './label';

export default (field) => html`
	${labelTpl(field)}
	<div class=${classes('field__inputWrapper')}>
		<textarea
			name=${once(field.inputName)}
			id=${once(field.id)}
			class=${classes('field__input', 'field__input--textarea')}
			data=${dataAttr('data' in field ? field.data : {})}
			attr=${attr(Object.assign({}, field.attributes, {'.value': field.value, '?readonly': field.readonly, '.disabled': field.disabled}))}></textarea>
	</div>`;

import artworks from './contents/artworks';

export default async (remoteApiRepository) => {
	const promises = await Promise.all([
		artworks(remoteApiRepository),
	]);
	return {
		contents: {
			artworks: promises[0],
		},
	};
};

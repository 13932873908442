import search from './../fields/search';
import artists from './../fields/artists';
import date from './../fields/date';
import sort from './../fields/sort';
import layout from './../fields/layout';
import classifications from '../fields/classifications';

export default async (remoteApiRepository) => {
	const blueprint = {
		fields: [
			{
				name: 'search',
				label: '',
				type: 'single',
				collapsable: false,
				hiddenOnMobile: false,
				itemClass: '',
				fieldData: await search(),
			},
			{
				name: 'artists',
				label: 'Künstler:innen',
				type: 'single',
				collapsable: true,
				hiddenOnMobile: false,
				itemClass: '',
				fieldData: await artists(remoteApiRepository),
			},
			{
				name: 'date',
				label: 'Datierung',
				type: 'group',
				collapsable: true,
				hiddenOnMobile: false,
				itemClass: 'date',
				fieldData: await date(),
			},
			{
				name: 'classifications',
				label: 'Gattung',
				type: 'single',
				collapsable: true,
				hiddenOnMobile: false,
				itemClass: '',
				fieldData: await classifications(remoteApiRepository),
			},
			{
				name: 'sort',
				label: 'Sortieren nach',
				type: 'single',
				collapsable: true,
				hiddenOnMobile: false,
				itemClass: '',
				fieldData: await sort(),
			},
			{
				name: 'layout',
				label: 'Anzeige',
				type: 'single',
				collapsable: true,
				hiddenOnMobile: true,
				itemClass: '',
				fieldData: await layout(),
			}
		]
	};

	return blueprint;
};

import {isObject} from '../utils/types';


const isIterable = (obj) => (Array.isArray(obj) || obj instanceof Map || obj instanceof Set || isObject(obj));



const map = (iterable, callback, recursive = false) => {
	if (Array.isArray(iterable)) {
		if (recursive) {
			const result = [];
			for (let i = 0, end = iterable.length; i < end; i++) {
				result[i] = (isIterable(iterable[i]) ? map(iterable[i], callback, true) : callback(iterable[i], i, iterable));
			}
			return result;
		}
		return iterable.map(callback);
	}
	if (iterable instanceof Map) {
		const result = new Map();
		for (const [key, value] of iterable) {
			result.set(key, (recursive && isIterable(value)) ? map(value, callback, true) : callback(value, key, iterable));
		}
		return result;
	}
	if (iterable instanceof Set) {
		const result = new Set();
		for (const [index, value] of iterable) {
			result.add((recursive && isIterable(value)) ? map(value, callback, true) : callback(value, index, iterable));
		}
		return result;
	}
	if (isObject(iterable)) {
		const result = {};
		for (const index in iterable) {
			if (iterable.hasOwnProperty(index)) {
				result[index] = (recursive && isIterable(iterable[index])) ? map(iterable[index], callback, true) : callback(iterable[index], index, iterable);
			}
		}
		return result;
	}
	console.error('not iterable', iterable);
	throw Error('An iterable object is required');
};


export default map;

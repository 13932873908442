import {trim} from '../utils/string';


const url = {
	get: function (path, params = {}) {
		if (path.indexOf('http') !== 0 && path.indexOf('/') !== 0) {
			path = url.baseUrl + (path.length ? '/' + trim(path, '/') : '');
		}
		return this.locale.format(path, params);
	},


	media: function (path) {
		return this.baseMediaUrl + (path.length ? '/' + trim(path, '/') : '');
	},


	base: function() {
		return this.baseUrl;
	},


	baseUrl: '',
	baseMediaUrl: '',
	locale: null
};

export default url;

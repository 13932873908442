import range from 'locutus/php/array/range';
import {isString, isFunction} from '../utils/types';


class PaginationItems {

	constructor({itemFactory, current, total, url, options = {}}) {
		this.itemFactory = itemFactory;
		this.options = Object.assign({
				paramPattern: '/page:$page',
				pagePlaceholder: '$page',
				omitFirstPage: true,
				sequence: ['prev', 'first', 'separator', 'range', 'separator', 'last', 'next'],
				range: 5
			}, options
		);

		this.items = null;
		this.total = +total;
		this.current = Math.max(1, Math.min(this.total, +current));
		this.url = url;
	}


	// useful for quick overview
	toString() {
		return this.getItems().join(' ');
	}


	get length() {
		return this.getItems().length;
	}

	count() {
        return this.getItems().length;
    }


	hasPrev() {
		return (this.current > 1);
	}


	hasNext() {
		return (this.current < this.total);
	}


	getTotal() {
		return this.total;
	}


	getCurrent() {
		return this.createItem(this.current);
	}


	getCurrentPage() {
		return this.current;
	}


	getCurrentUrl() {
		return this.getPageUrl(this.current);
	}


	getPrev() {
		if (this.hasPrev()) {
			return this.createItem(this.current - 1);
		}
		throw new Error('Requested page is out of range');
	}


	getPrevPage() {
		if (this.hasPrev()) {
			return this.current - 1;
		}
		throw new Error('Requested page is out of range');
	}


	getPrevUrl() {
		if (this.hasPrev()) {
			return this.getPageUrl(this.current - 1);
		}
		throw new Error('Requested page is out of range');
	}


	getNext() {
		if (this.hasNext()) {
			return this.createItem(this.current + 1);
		}
		throw new Error('Requested page is out of range');
	}


	getNextPage() {
		if (this.hasNext()) {
			return this.current + 1;
		}
		throw new Error('Requested page is out of range');
	}


	getNextUrl() {
		if (this.hasNext()) {
			return this.getPageUrl(this.current + 1);
		}
		throw new Error('Requested page is out of range');
	}


	getFirst() {
		return this.createItem(1);
	}


	getFirstPage() {
		return 1;
	}


	getFirstUrl() {
		return this.getPageUrl(1);
	}


	getLast() {
		return this.createItem(this.total);
	}


	getLastPage() {
		return this.total;
	}


	getLastUrl() {
		return this.getPageUrl(this.total);
	}


	getItem(number)	{
		number = +number;
		if (number > 0 && number <= this.total) {
			return this.createItem(number);
		}
		throw new Error('Requested page is out of range');
	}


	getPageUrl(number) {
		number = +number;
		if (number > 0 && number <= this.total) {
			if (isString(this.url)) {
				const pattern = this.options.paramPattern;
				const placeholder = this.optionspagePlaceholder;
				const omitFirstPage = !!this.options.omitFirstPage;
				if (number === 1 && omitFirstPage) {
					return this.url.replace(pattern, '');
				}
				return this.url.replace(placeholder, number);
			}
			if (isFunction(this.url)) {
				return this.url(number);
			}
			throw new Error('Invalid URL, unable to create pagination links');
		}
		throw new Error('Requested page is out of range');
	}


	getItems() {
		if (this.items === null) {
			const current = this.current;
			const total = this.total;
			const limit = +this.options.range;
			const items = [];
			const rangeArray = this.pageRange(current, total, limit);
			const rangeFirst = +rangeArray[0];
			const rangeLast = +rangeArray[rangeArray.length - 1];

			let firstIndex = null;
			for (let index = 0, end = this.options.sequence.length; index < end; index++) {
				const type = this.options.sequence[index];
				switch (type) {
					case 'prev':
						if (current > 1) {
							items.push(this.createItem(current - 1, {prev: true}));
						}
						break;

					case 'next':
						if (current < total) {
							items.push(this.createItem(current + 1, {next: true}));
						}
						break;

					case 'first':
						if (rangeFirst > 1) {
							firstIndex = index;
							items.push(this.createItem(1));
						}
						break;

					case 'last':
						if (rangeLast < total) {
							items.push(this.createItem(total));
						}
						break;

					case 'range':
						for (let page of rangeArray) {
							page = +page;
							if (page === 1 && firstIndex !== null) {
								continue;
							}
							items.push(this.createItem(page));
						}
						break;

					case 'separator':
						if (
							(firstIndex === index - 1 && index < (this.options.sequence.length - 1) && this.options.sequence[index + 1] === 'range' && rangeFirst > 2) ||
							(index > 0 && this.options.sequence[index -1] === 'range' && index < (this.options.sequence.length - 1) && this.options.sequence[index + 1] === 'last' && rangeLast < (total - 1))
						) {
							items.push(this.createSeparator());
						}
						break;
					default:
						break;
				}
			}
			this.items = items;
		}
		return this.items;
	}


	createItem(page, status = {}) {
		if (page === 1) {
			status.first = true;
		}
		if (page === this.total) {
			status.last = true;
		}
		if (page === this.current) {
			status.current = true;
		}
		if (page !== this.current) {
			status.link = true;
		}

		const url = ('link' in status && status.link ? this.getPageUrl(page) : '');
		return this.itemFactory({page: page, url: url, status: status});
	}


	createSeparator() {
		return this.itemFactory({status: {separator: true}});
	}


	pageRange(current, total, limit) {
		if (total <= limit) {
			return range(1, total);
		}

		const half = +Math.floor(limit / 2);
		let first = current - half;
		let last = current + half;

		if (first <= 0) {
			last += Math.abs(first) + 1;
			first = 1;
		}

		if (last > total) {
			first -= last - total;
			last = total;
		}

		return range(first, last);
	}

}


export default PaginationItems;

import {html} from '../../../common/tpl/tpl';
import {classes, attr, dataAttr, repeat} from '../../../common/tpl/directives';
import icon from './media/icon';

export default (blueprint) => {
	const paginationItems = blueprint;

	const paginationItemTpl = (page, isCurrent) => {
		const attributes = {
			ariaHidden: isCurrent,
			tabIndex: isCurrent ? 0 : -1
		};

		return html`
			<li class=${classes('pagination__item')}>
				<button
					class=${classes('pagination__link', 'button', 'button--secondary', 'button--pagination', isCurrent ? 'pagination__link--current' : '')}
					data=${dataAttr({paginationButton: '', pageNumber: page})}
					attr=${attr(attributes)}
				>
					<span class=${classes('button__label')}>${page}</span>
				</button>
			</li>
		`;
	};


	const navigationButtonTpl = (page, type) => html`
		<li class=${classes('pagination__item pagination__item--nav', type === 'prev' ? 'pagination__item--prev' : 'pagination__item--next')}>
			<button
				class=${classes('pagination__link', 'button button--pagination', type === 'prev' ? 'button--prev' : 'button--next')}
				data=${dataAttr({paginationButton: '', pageNumber: page})}
			>
				<span class=${classes('button__icon')}>${icon(type === 'prev' ? '16-gallery-arrow-left-small' : '16-gallery-arrow-right-small')}</span>
			</button>
		</li>
	`;

	const separatorTpl = () => html`
		<li class=${classes('pagination__item pagination__item--indicator')}>
			<span>...</span>
		</li>
	`;


	return html`
		<div class=${classes('pagination', 'pagination--artworks')}>
			<ul class=${classes('pagination__items')}>
				${repeat(paginationItems, (paginationItem, index) => {
					const page = paginationItem.page;
					const status = paginationItem.status;
					const isCurrent = paginationItem.status.current;
					let template = null;

					if (status.link && status.prev) {
						template = navigationButtonTpl(page, 'prev');
					}
					if (status.link) {
						if (!status.prev && !status.next) {
							template = paginationItemTpl(page, isCurrent);
						}
					}
					if (status.current) {
						template = paginationItemTpl(page, isCurrent);
					}
					if (status.separator) {
						template = separatorTpl('next');
					}
					if (status.link && status.next) {
						template = navigationButtonTpl(page, 'next');
					}

					return template;
				})}
			</ul>
		</div>
	`;
};

export default async (remoteApiRepository) => {
	const filterOptions = await (remoteApiRepository.getDynamicFilterData());
	const artists = filterOptions.artists;

	const options = [{
		label: 'Alle Künstler:innen',
		value: ''
	}];

	if (artists) {
		const sortedArtists = artists.sort((a, b) => {
			const nameA = a.name.toUpperCase();
			const nameB = b.name.toUpperCase();
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			return 0;
		});


		sortedArtists.forEach((artist) => {
			const label = artist.name;
			const value = artist.uuid;
			options.push({
				label: label,
				value: value
			});
		});
	}

	const blueprint = {
		name: 'artistsField',
		inputName: 'artistsField',
		id: 'artistsSelect',
		type: 'select',
		value: '',
		attributes: {},
		classes: [],
		modifiers: [],
		data: {},
		options: options,
		disabled: false,
		required: false,
		hidden: false,
		readonly: false
	};

	return blueprint;
};

import {html} from '../../../../../common/tpl/tpl';
import {classes, repeat} from '../../../../../common/tpl/directives';
import errorTpl from './error';


export default (errors) => html`
	<ul class=${classes('field__errors')}>
		${repeat(errors, (error) => errorTpl(error))}
	</ul>`;


import PageComponent from '../../common/component/page-component';

class ModalController extends PageComponent {

	constructor({
		root,
		element,
		closeOnModal = false

	}) {
		super({root: root, element: element});
		this.defaults.closeOnModal = closeOnModal;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		const closeOnModal = data.closeOnModal;

		if (closeOnModal) {
			this.modalWrapper = this.element.querySelector(this.dataSelector('modalWrapper'));
			this.listeners.click = this.events.on(this.element, 'click', this.onClick.bind(this));
		}
	}


	onClick(event, target) {
		if (target === this.element || target === this.modalWrapper) {
			if (this.contexts.getCurrentContext().getName() === 'modal') {
				this.contexts.pop();
			}
		}
	}
}

export default ModalController;

import {html} from '../../../../../common/tpl/tpl';
import {dataAttr, attr, once} from '../../../../../common/tpl/directives';

// add attributes
// add value

export default (field) => {
	const attributes = Object.assign({}, field.attributes);
	const data = Object.assign({
		component: 'hiddenField',
		field: field.name,
		fieldType: 'hidden'
	}, 'data' in field ? field.data : {});
	if ('customType' in field && field.customType) {
		data.customType = true;
		data.value = field.value;
	} else {
		attributes['.value'] = field.value;
	}

	return html`<input type="hidden"
		name=${once(field.inputName)}
		id=${once(field.id)}
		attr=${attr(attributes)}
		data=${dataAttr(data)} />`;
};

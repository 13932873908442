import {html, nothing} from '../../../../../common/tpl/tpl';
import {classes, when, waitFor} from '../../../../../common/tpl/directives';
import icon from '../../media/icon';
import loadingTpl from '../../loading';


export default (params) => html`
	<span class=${classes([
		'configuratorPanelSelectorLabel',
		(params.required ? 'configuratorPanelSelectorLabel--required' : ''),
		('value' in params && params.value ? 'configuratorPanelSelectorLabel--filled' : '')
	])}>
		<span class=${classes('configuratorPanelSelectorLabel__label')}>
			${params.label}
			<span class=${classes('configuratorPanelSelectorLabel__requiredIcon', 'configuratorPanelSelectorLabel__requiredIcon--empty')}>${icon('empty')}</span>
			<span class=${classes('configuratorPanelSelectorLabel__requiredIcon', 'configuratorPanelSelectorLabel__requiredIcon--filled')}>${icon('done')}</span>
		</span>

		${when('value' in params && params.value, () => html `
			<span class=${classes('configuratorPanelSelectorLabel__value')}>
				${waitFor(params.value, (value) => html`
					${value.value}
					${value.details ? html`<span class=${classes('configuratorPanelSelectorLabel__valueDetails')}>${value.details}</span>` : nothing}
				`,
				loadingTpl())}
			</span>
		`, () => html`
			${when('description' in params && params.description.length, () => html `
				<span class=${classes('configuratorPanelSelectorLabel__description')}>${params.description}</span>
			`)}
		`)}
		<span class=${classes('configuratorPanelSelectorLabel__actionIcon')}>${icon('arrow-right')}</span>
	</span>
`;

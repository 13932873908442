// App
import App from '../../common/app/app';

// Component
import PageComponents from '../../common/component/page-components';
import PageComponent from '../../common/component/page-component';
import PageComponentFactory from '../../common/component/page-component-factory';
import componentsMixin from '../../common/component/components-mixin';


import contextConfig from '../../common/context/_config';
import dialogConfig from '../../common/dialog/_config';
import diConfig from '../../common/di/_config';

// Dom
import ClassListParser from '../../common/dom/class-list-parser';
import ClassList from '../../common/dom/class-list';
import CssData from '../../common/dom/css-data';
import DataAttrParser from '../../common/dom/data-attr-parser';
import DataAttr from '../../common/dom/data-attr';
import ThreeStateTransition from '../../common/dom/three-state-transition';
import domMixin from '../../common/dom/dom-mixin';

// Events
// import eventsConfig from '../../common/events/_config';
import Events from '../../common/events/events';
import listenerMixin from '../../common/events/listener-mixin';
import asyncEventMixin from '../../common/events/async-event-mixin';

import formConfig from '../../common/form/_config';
import localeConfig from '../../common/locale/_config';

// Media
import AsyncPicture from '../../common/media/async-picture';
import notificationsConfig from '../../common/notifications/_config';
import preloadConfig from '../../common/preload/_config';
import HistoryApi from '../../common/navigation/history-api';

// Navigation
import navigationConfig from '../../common/navigation/_config';
// import paginationConfig from '../../common/pagination/_config';
import PaginationItems from '../../common/pagination/pagination-items';
import PaginationItem from '../../common/pagination/pagination-item';

// Widget
import widgetConfig from '../../common/widget/_config';

import slideshowConfig from '../../common/slideshow/_config';

const config = (di) => {
	// App
	di.setType({type: App, name: 'App', mixins: ['domMixin', 'navigationMixin', 'componentsMixin', 'contextsMixin'], setters: {
			injectFontChecker: di.lazyGet('preload/fontChecker'),
			injectStyleChecker: di.lazyGet('preload/styleChecker')
		}
	});

	// Component
	di
		.setType({
			type: PageComponents, name: 'PageComponents', mixins: ['domMixin'], setters: {
				injectFactory: di.lazyGet('component/factory')
			}
		})
		.set('component/components', di.lazyNew('PageComponents'))

		.setType({type: PageComponent, name: 'PageComponent', mixins: ['domMixin', 'listenerMixin', 'asyncEventMixin', 'componentsMixin', 'contextsMixin']})

		.setType({type: PageComponentFactory, name: 'PageComponentFactory', parent: 'Factory'})
		.set('component/factory', di.lazyNew('PageComponentFactory'))

		.setMixin({
			mixin: componentsMixin, name: 'componentsMixin', setters: {
				injectComponents: di.lazyGet('component/components')
			}
		})
	;

	contextConfig(di);
	dialogConfig(di);
	diConfig(di);

	// historyApi
	di
		.setType({type: HistoryApi, name: 'HistoryApi', params: {
				events: di.lazyGet('events/events')
			}
		})
		.set('navigation/history', di.lazyNew('HistoryApi'));



	// Dom
	di
		.setType({type: DataAttrParser, name: 'DataAttrParser', params: {
				prefix: di.lazyValue('dom/dataAttrPrefix', '')
			}
		})
		.set('dom/dataAttrParser', di.lazyNew('DataAttrParser'))

		.setType({type: DataAttr, name: 'DataAttr', params: {
				parser: di.lazyGet('dom/dataAttrParser')
			}
		})
		.set('dom/dataAttrFactory', di.newFactory('DataAttr'))

		.setType({type: CssData, name: 'CssData'})
		.set('dom/cssDataFactory', di.newFactory('CssData'))

		.setType({type: ClassListParser, name: 'ClassListParser', params: {
				prefix: di.lazyValue('dom/classPrefix', ''),
				basePrefix: di.lazyValue('dom/baseClassPrefix', '')
			}
		})
		.set('dom/classListParser', di.lazyNew('ClassListParser'))

		.setType({type: ClassList, name: 'ClassList', params: {
				parser: di.lazyGet('dom/classListParser')
			}
		})
		.set('dom/classListFactory', di.newFactory('ClassList'))

		.setMixin({mixin: domMixin, name: 'domMixin', setters: {
				injectEvents: di.lazyGet('events/events'),
				injectDataAttrParser: di.lazyGet('dom/dataAttrParser'),
				injectDataAttrFactory: di.lazyGet('dom/dataAttrFactory'),
				injectCssDataFactory: di.lazyGet('dom/cssDataFactory'),
				injectClassListParser: di.lazyGet('dom/classListParser'),
				injectClassListFactory: di.lazyGet('dom/classListFactory'),
				injectThreeStateTransitionFactory: di.lazyGet('dom/threeStateTransition')
			}
		})

		.setType({type: ThreeStateTransition, name: 'ThreeStateTransition', params: {
				classListFactory: di.lazyGet('dom/classListFactory')
			}
		})

		.set('dom/threeStateTransition', (element, animatedElement = null) => di.newInstance('ThreeStateTransition', {element: element, animatedElement: animatedElement}))
	;


	// Events
	di
		.setType({type: Events, name: 'Events'})
		.set('events/events', di.lazyNew('Events'))
		.setMixin({mixin: listenerMixin, name: 'listenerMixin', initCall: 'initListeners'})
		.setMixin({mixin: asyncEventMixin, name: 'asyncEventMixin', initCall: 'initAsyncEvents'})
	;


	formConfig(di);
	localeConfig(di);

	// Media
	di
		.setType({type: AsyncPicture, name: 'AsyncPicture', parent: 'PageComponent'})
	;


	notificationsConfig(di);
	preloadConfig(di);
	widgetConfig(di);

	// Navigation
	navigationConfig(di);
	// paginationConfig(di);
	di
		.setType({type: PaginationItem, name: 'PaginationItem'})
		.setType({type: PaginationItems, name: 'PaginationItems', params: {
			itemFactory: di.lazyGet('pagination/itemFactory')
		}})
		.set('pagination/items', di.lazyNew('PaginationItems'))
		.set('pagination/itemFactory', di.newFactory('PaginationItem'))
	;

	slideshowConfig(di);
};


export default config;

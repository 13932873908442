import {html, nothing} from '../../../../../common/tpl/tpl';
import {classes, unsafeHtml} from '../../../../../common/tpl/directives';


export default (field) => {
	if ('description' in field && field.description.length) {
		return html`<div class=${classes('field__description')}>${unsafeHtml(field.description)}</div>`;
	}
	return nothing;
};

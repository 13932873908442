import {html} from '../../../../common/tpl/tpl';
import {classes, dataAttr} from '../../../../common/tpl/directives';
import blockWrapperTpl from './../block-wrapper';


export default (blueprint) => {
	const blocks = blueprint.blocks;

	return html`
	<section class=${classes('section', 'section--dynamicFilterForm')} data=${dataAttr({dynamicTemplateSection: 'artworks'})}>
		<div class=${classes('section__content')}>
			${blocks.map((block) => html`${blockWrapperTpl(block)}`)}
		</div>
	</section>`;
};


export default async (remoteApiRepository) => {
	const filterOptions = await (remoteApiRepository.getDynamicFilterData());
	const classifications = filterOptions.classifications;
	const fields = [];

	if (classifications) {
		classifications.forEach((classification, index) => {
			const label = classification.name;
			const value = classification.id;
			fields.push({
				name: 'classificationField',
				inputName: 'classificationField',
				id: 'classifications-input-' + index,
				type: 'checkbox',
				label: label,
				attributes: {
					value: value,
				},
				classes: [],
				modifiers: [],
				data: {},
				disabled: false,
				required: false,
				hidden: false,
				readonly: false
			});
		});
	}

	const blueprint = {
		type: 'group',
		fields: fields
	};

	return blueprint;
};

import formConstants from './_constants';


const fieldsGroupMixin = (Base = class Empty {}) => class extends Base {

	fetchFields() {
		const fields = new Map();
		const fieldComponents = this.components.queryComponents(this.element, this.dataSelector(formConstants.fieldSelectorAttribute));
		for (let i = 0; i < fieldComponents.length; i++) {
			const fieldComponent = fieldComponents[i];
			if (fieldComponent.getElement().parentNode.closest(this.dataSelector(formConstants.groupAttribute)) === this.element) {
				const key = fieldComponent.getName();
				fields.set(key, fieldComponent);
			}
		}
		return fields;
	}


	isArray() {
		return false;
	}


	hasField(name) {
		const fields = this.fetchFields();
		return fields.has(name);
	}


	getField(name) {
		const fields = this.fetchFields();
		if (fields.has(name)) {
			return fields.get(name);
		}
		throw new Error('Field ' + name + ' not defined');
	}


	getFields() {
		return this.fetchFields();
	}


	count() {
		return this.fetchFields().size;
	}


	getValue() {
		const fields = this.fetchFields();
		const isArray = this.isArray();
		const values = (isArray ? [] : {});
		for (const [name, field] of fields) {
			values[name] = field.getValue();
		}
		return values;
	}


	setValue(value) {
		const fields = this.fetchFields();
		const isArray = Array.isArray(value);
		for (const [name, field] of fields) {
			if ((isArray && name < value.length) || (!isArray && name in value)) {
				field.setValue(value[name]);
			}
		}
	}


	resetValue() {
		for (const field of this.fetchFields().values()) {
			field.resetValue();
		}
	}


	resetErrors() {
		for (const field of this.fetchFields().values()) {
			field.resetErrors();
		}
		if ('setOwnErrors' in this) {
			this.setOwnErrors([]);
		}
	}


	setValueAndErrors(data) {
		const fields = this.fetchFields();
		if ('fields' in data) {
			const isArray = Array.isArray(data.fields);
			for (const [name, field] of fields) {
				if ((isArray && name < data.fields.length) || (!isArray && name in data.fields)) {
					field.setValue(data.fields[name].value);
					field.setErrors('errors' in data.fields[name] ? data.fields[name].errors : []);
				}
			}
		}
		if ('setOwnErrors' in this) {
			this.setOwnErrors('errors' in data ? data.errors : []);
		}
	}


	reset() {
		this.resetValue();
		this.resetErrors();
	}

};


export default fieldsGroupMixin;

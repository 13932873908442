import Notifications from './notifications';
import notificationsMixin from './notifications-mixin';


export default (di) => {
	di
		.setType({type: Notifications, name: 'Notifications', mixins: ['domMixin'], params: {
				template: di.lazyGet('template/template'),
				selector: di.lazyGetCall('dom/dataAttrParser', 'getSelector', 'notifications')
			}
		})
		.setMixin({mixin: notificationsMixin, name: 'notificationsMixin', setters: {
				injectNotifications: di.lazyGet('notifications/notifications')
			}
		})
		.set('notifications/notifications', di.lazyNew('Notifications'))
	;
};

import {html, nothing} from '../../../../common/tpl/tpl';
import {classes as c, dataAttr, when, repeat, waitFor} from '../../../../common/tpl/directives';
import fieldTpl from './field';
import loadingTpl from '../loading';


export default (fields, values, parentEntry = null) =>  {
	const data = {
		fields: null
	};

	const classes = ['fields'];


	if (parentEntry && parentEntry.type === 'group') {
		classes.push('fields--group');
	}
	if (parentEntry && (parentEntry.type === 'group' || parentEntry.type === 'array') && 'collapsable' in parentEntry && parentEntry.collapsable) {
		data.component = 'Collapsable';
		data.id = parentEntry.id;
		if ('collapsed' in parentEntry && parentEntry.collapsed) {
			classes.push('js-collapsed');
		}
	}

	const groupLabelTpl = (label) => html`
		<span class=${c('fields__groupLabel')}>
			${label}
		</span>
	`;
	return html`
	<div class=${c(classes)} data=${dataAttr(data)}>
		${repeat(fields, (field, name) => name, (field, name) => html`${when(field && (!('allowed' in field) || field.allowed), () => {
			let value = undefined;
			let errors = [];
			let flags = {};
			let groupLabel = '';
			const hasGroupLabel = parentEntry.type === 'group' && field.groupLabel;
			if (hasGroupLabel) {
				groupLabel = field.groupLabel;
			}
			if (values && values[name]) {
				value = ('fields' in values[name] ? values[name].fields : ('value' in values[name] ? values[name].value : undefined));
				errors = ('errors' in values[name] ? values[name].errors : []);
				flags = ('flags' in values[name] ? values[name].flags : {});
			}
			return waitFor(field, (resolvedField) => html`
				${fieldTpl(resolvedField, name, value, errors, flags)}
				${hasGroupLabel ? groupLabelTpl(groupLabel) : nothing}
			`, html`
				<div class=${c('fields__loading')}>
					${loadingTpl()}
				</div>
			`);
		})}`)}
	</div>
	`;
};


import httpRequest from 'superagent/lib/client';
import {leftTrim, rightTrim} from '../../common/utils/string';
import newResponse from './data-response';

const SECOND = 1000;


class ApiDriver {
    constructor({itemsUrl, defaultOptions = {}}) {
		this.itemsUrl = rightTrim(itemsUrl, '/');
		this.defaultOptions = Object.assign({
			timeout: 10 * SECOND
		}, defaultOptions);
	}


	prepareCommand(command) {
		return this.itemsUrl + '/' + leftTrim(command, '/');
	}


    prepareParams(params) {
		params = Object.assign({}, params);
		return params;
	}


	prepareOptions(options) {
		return Object.assign({}, this.defaultOptions, options);
	}


	processResponse(response) {
		const processedResponse = JSON.parse(response.text);

		return newResponse({
			success: true,
			data: processedResponse
		});
	}


	processError(error) {
		return newResponse({
			success: false,
			error: error
		});
	}


	async request(method, command, params = {}, options = {}) {
		// GET, DELETE, HEAD, PATCH, POST, PUT
		const methodName = method.toLowerCase();
		command = this.prepareCommand(command);
		params = this.prepareParams(params);
		options = this.prepareOptions(options);

		const request = httpRequest[methodName](command)
			.set('X-Requested-With', 'XMLHttpRequest')
			.set('Accept', 'application/json');

		if (options.onProgress) {
			request.on('progress', options.onProgress);
		}
		if (options.timeout) {
			request.timeout(options.timeout);
		}

		try {
			let response;
			if (methodName === 'get' || methodName === 'head') {
				request.query(params);
				response = await request.send();
			} else {
				response = await request.send(params);
			}
			return this.processResponse(response);
			// res.body, res.headers, res.status
		} catch(error) {
			return this.processError(error);
		}
	}

}


export default ApiDriver;

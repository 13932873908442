import httpRequest from 'superagent/lib/client';

class IconFilesLoader {

    constructor() {
		this.iconMaps = {};
		this.embeddedIconMaps = {};
    }


    load(files) {
		this.urls = {};
		const promises = [];
		for (const entry of files) {
			const name = entry.name;
			const url = entry.url;
			this.urls[name] = url;
			promises.push(new Promise((resolve) => {
				const req = httpRequest.get(url);
				req.end((error, response) => {
					if (error) {
						console.error('Unable to load icon file ' + url);
					} else {
						this.parseIconsFile(name, response.text);
					}
					resolve();
				});
			}));
		}
		return Promise.all(promises);
	}


	parseIconsFile(name, content) {
		const map = {};
		this.iconMaps[name] = map;
		const node = document.createElement('div');
		node.innerHTML = content;
		const icons = node.querySelectorAll('symbol');
		for (const icon of icons) {
			const id = icon.getAttribute('id');
			map[id] = this.parseIconInfo(icon);
		}
	}


	getFileUrl(name) {
		if (name in this.urls) {
			return this.urls[name];
		}
		throw new Error('Icon File ' + name + ' not loaded');
	}


	getIconInfo(id, file = undefined) {
		if (file === undefined) {
			return this.getEmbeddedIconInfo(id);
		}
		if (file in this.iconMaps) {
			if (id in this.iconMaps[file]) {
				return this.iconMaps[file][id];
			} else {
				throw new Error('Icon ' + id + ' not found in file ' + file);
			}
		} else {
			throw new Error('Icon File ' + file + ' not loaded');
		}
	}


	getEmbeddedIconInfo(id) {
		if (!(id in this.embeddedIconMaps)) {
			const icon = document.getElementById(id);
			if (icon) {
				this.embeddedIconMaps[id] = this.parseIconInfo(icon);
			} else {
				throw new Error('Embedded icon ' + id + ' not found');
			}
		}
		return this.embeddedIconMaps[id];
	}


	parseIconInfo(icon) {
		let title = icon.querySelector('title');
		title = title ? title.innerHTML : '';
		const viewBox = icon.getAttribute('viewBox');
		if (!viewBox) {
			throw new Error('icon ' + icon.getAttribute('id') + ' does not have a viewBox');
		}
		const viewBoxParts = viewBox.trim().split(/\s+/);
		const width = Number(viewBoxParts[2]);
		const height = Number(viewBoxParts[3]);
		return {viewBox: viewBox, title: title, width: width, height: height};
	}

}


export default IconFilesLoader;

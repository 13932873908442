import {html as litHtml, svg as litSvg, render, nothing} from 'lit-html';

const applyPlugins = (strings, values, plugins, type) => {
	if (plugins.length) {
		let inStrings = strings.slice();
		let inValues = values.slice();
		let outStrings = [];
		let outValues = [];
		for (let i = 0; i < plugins.length; i++) {
			outStrings = [];
			outValues = [];
			for (let index = 0; index < inStrings.length; index++) {
				const match = plugins[i](index, inStrings, inValues, outStrings, outValues, type);
				if (!match) {
					outStrings.push(inStrings[index]);
					if (index < inValues.length) {
						outValues.push(inValues[index]);
					}
				}
			}
			inStrings = outStrings;
			inValues = outValues;
		}
		return [outStrings, outValues];
	}

	return [strings, values];
};


const html = (strings, ...values) => {
	const [outStrings, outValues] = applyPlugins(strings, values, html.plugins, 'html');
	return litHtml(outStrings, ...outValues);
};

const svg = (strings, ...values) => {
	const [outStrings, outValues] = applyPlugins(strings, values, svg.plugins, 'svg');
	return litSvg(outStrings, ...outValues);
};

html.plugins = [];
svg.plugins = [];

// tpl.plugins.push((index, inStrings, inValues, outStrings, outValues) => {
// 	const str = inStrings[index];
// 	const pattern = ' $attributes=';
// 	if (index < inValues.length && str.lastIndexOf(pattern) === str.length - pattern.length) {
// 		let first = true;
// 		for (const name in inValues[index]) {
// 			if (inValues[index].hasOwnProperty(name)) {
// 				const newStr = (first ? str.substr(0, str.length - pattern.length) + ' ' : ' ');
// 				outStrings.push(newStr + name + '=');
// 				outValues.push(inValues[index][name]);
// 				first = false;
// 			}
// 		}

// 		return true;
// 	}
// 	return false;
// });


export {html, svg, litHtml, litSvg, render, nothing};

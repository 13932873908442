import {html} from '../../../../common/tpl/tpl';
import {classes, dataAttr, repeat} from '../../../../common/tpl/directives';
import actionTpl from '../action/action';

export default (actions, field) => html`
	<div class=${classes('field__actions', 'field__actions--' + field.type)} data=${dataAttr({fieldActions: null})}>
		<ul class=${classes('field__actionItems')}>
			${repeat(actions || [], (action, name) => name, (action, actionName) => html`
				<li class=${classes('field__actionItem', 'field__actionItem--' + name)}>
					${actionTpl(action)}
				</li>
			`)}
		</ul>
	</div>`;

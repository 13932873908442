import {until} from 'lit-html/directives/until';

export default (value, func, alternative) => until(
	new Promise(async (resolve) => {
		resolve(func(await value));
	}),
	// it is better to wrap also the alternative in a promise, this allows the first one to not fully re-render when it is already resolved
	new Promise((resolve) => {
		setTimeout(() => {
			resolve(alternative);
		}, 0);
	})
);

import PageComponent from '../component/page-component';
import baseFieldMixin from './base-field-mixin';
// import formConstants from './_constants';


class BaseField extends baseFieldMixin(PageComponent) {

	constructor({
		root,
		element,
		invalidClass = 'invalid',
		errorTpl
	}) {
		super({root: root, element: element});
		this.invalidClass = invalidClass;
		this.errorTpl = errorTpl;
	}


	// prepare() {
	// 	console.log('preparing', this);
	// }


}


export default BaseField;

import DynamicTemplate from '../template-controller/dynamic-template';
// import pagination from './templates/pagination';


class DynamicSection extends DynamicTemplate {

	injectPaginationItems(paginationItems) {
		this.paginationItems = paginationItems;
	}

	async initData() {
		this.checkInitalSearchParams().then(() => {
			super.initData();
		});
	}

	async checkInitalSearchParams() {
		this.remoteApiRespository.getFilterOptions().then((dynamicFilterData) => {
			const initialArtistSearchValue = this.dataAttr(this.element).get('initialArtistSearch');

			if (initialArtistSearchValue) {
				const isInt = Number.isInteger(initialArtistSearchValue);
				const artists = dynamicFilterData.artists;
				const filterData = this.remoteApiRespository.getFilterData();

				for (const artist of artists) {
					const uuid = parseInt(artist.uuid, 10);

					if (isInt) {
						if (uuid === initialArtistSearchValue) {
							filterData.artistId = uuid;
							break;
						}
					} else {
						const name = artist.name.toLowerCase();
						const re = new RegExp(initialArtistSearchValue.toLowerCase(), 'g');
						const match = name.match(re);
						if (match) {
							filterData.artistId = uuid;
							break;
						}
					}
				}

				this.remoteApiRespository.getItems(filterData).then((response) => {
					const itemsData = response;
					this.updateItemsBlueprint(itemsData, filterData);
				});
			}
			return Promise.resolve();
		});
	}


	async updateItemsBlueprint(itemsData, filterData) {
		const items = itemsData.items;
		this.updatePagination(filterData, itemsData);
		super.updateItemsBlueprint(items, filterData);
	}


	async updatePagination(filterData, itemsData) {
		const itemsCount = itemsData.itemsCount;
		const limit = filterData.limit;
		const offset = filterData.offset;
		const current = offset < limit ? 1 : (offset / limit) + 1;
		this.paginationItems.url = '/';
		this.paginationItems.current = current;
		this.paginationItems.total = Math.ceil(itemsCount / filterData.limit);
		this.paginationItems.options.range = 2;
		this.paginationItems.items = null;

		const paginationList = itemsCount !== 0 ? this.paginationItems.getItems() : [];
		super.updatePaginationBlueprint(paginationList);
	}

	async updateResultListLayout(layout) {
		super.updateResultListLayout(layout);
	}

	async updateResultListState(initial, loading) {
		super.updateResultListState(initial, loading);
	}
}


export default DynamicSection;

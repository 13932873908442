import {html} from '../../../common/tpl/tpl';
import {classes} from '../../../common/tpl/directives';
import artworksTpl from './content/artworks';


export default (blueprints) => html`
		<div class=${classes('sections__content')}>
			${artworksTpl(blueprints.contents.artworks)}
		</div>
`;

import domMixin from '../dom/dom-mixin';
import localeMixin from './locale-mixin';
import escape from '../template/escape';


class LocaleDynamicTransator extends domMixin(localeMixin()) {

    constructor({
		root = null,
		translateIdAttribute = 'dynamicTranslateId',
		translateEscapeAttribute = 'dynamicTranslateEscape',
		translatePageTitleAttribute = 'dynamicTranslatePageTitle',
		escapeDefault = false
	} = {}) {
		super();
		this.translateIdAttribute = translateIdAttribute;
		this.translateEscapeAttribute = translateEscapeAttribute;
		this.translatePageTitleAttribute = translatePageTitleAttribute;
		this.escapeDefault = escapeDefault;
		this.root = root ? root : document.body;
	}


	init() {
		this.changeListener = this.events.on(this.root, this.locale.getChangeEventName(), this.onLangChange.bind(this));
	}


	clear() {
		this.changeListener.destroy();
	}


	onLangChange(event) {
		const elements = this.root.querySelectorAll(this.dataSelector(this.translateIdAttribute));
		for (const element of elements) {
			const data = this.dataAttr(element);
			const id = String(data.get(this.translateIdAttribute, ''));
			if (id.length) {
				const doEscape = !!data.get(this.translateEscapeAttribute, this.escapeDefault);
				const pageTitleId = String(data.get(this.translatePageTitleAttribute, ''));
				const text = this.locale.get(id);
				element.innerHTML = doEscape ? escape(text) : text;
				if (pageTitleId.length) {
					document.title = this.locale.get(pageTitleId);
				}
			}
		}
	}

}

export default LocaleDynamicTransator;

import PageComponent from '../../common/component/page-component';
import {getViewportWidth} from './../../common/utils/size';


class MainMenu extends PageComponent {

	constructor({
		root,
		element,
		mainMenuNavAttribute = 'mainMenuNav',
		headerAttribute = 'header',
		togglerAttribute = 'mainMenuNavToggler',
		togglerIdAttribute = 'mainMenuTogglerId',
		togglerLabelAttribute = 'mainMenuTogglerLabel',
		subMenuLayerIdAttribute = 'subMenuLayerId',
		subMenuLayerAttribute = 'subMenuLayer',
		subMenuAttribute = 'subMenu',
		currentSubNavLevelAttribute = 'currentSubNavLevel',

		subMenuVisibleModifier = 'subMenuVisible',
		visibleModifier = 'visible',
		activeModifier = 'active',
	}) {
		super({root: root, element: element});
		this.togglerAttribute = togglerAttribute;
		this.mainMenuNavAttribute = mainMenuNavAttribute;
		this.subMenuLayerIdAttribute = subMenuLayerIdAttribute;
		this.subMenuLayerAttribute = subMenuLayerAttribute;
		this.togglerIdAttribute = togglerIdAttribute;
		this.togglerLabelAttribute = togglerLabelAttribute;
		this.subMenuAttribute = subMenuAttribute;
		this.currentSubNavLevelAttribute = currentSubNavLevelAttribute;
		this.headerAttribute = headerAttribute;

		this.subMenuVisibleModifier = subMenuVisibleModifier;
		this.visibleModifier = visibleModifier;
		this.activeModifier = activeModifier;

		this.currentSubNavLevel = 0;
		this.isAnimating = false;
		this.headerNode = null;
		this.headerHeight = 0;
	}


	prepare() {
		this.SubMenuComponent = this.components.queryComponent(this.root, this.dataSelector('subMenu'));
		this.mainMenuNavNode = this.element.querySelector(this.dataSelector(this.mainMenuNavAttribute));
		this.headerNode = this.root.querySelector(this.dataSelector(this.headerAttribute));
		this.setMainMenuPosition();
		this.initListener();
	}


	initListener() {
		this.listeners.click = this.events.on(this.mainMenuNavNode, this.dataSelector(this.togglerAttribute), 'click', this.onClick.bind(this));
	}


	setMainMenuPosition() {
		this.mainMenuNavHeight = this.mainMenuNavNode.getBoundingClientRect().height;
		this.headerHeight = this.headerNode.getBoundingClientRect().height;
		const windowWidth = getViewportWidth();
		const appliedHeight = windowWidth > 1200 ? this.mainMenuNavHeight : this.headerHeight;
		this.element.style.top = appliedHeight + 'px';
	}


	onClick(event, target) {
		if (this.contexts.getCurrentContext().name !== 'menu') {
			this.contexts.push('menu');

			// if not allready in menu context, wait the header transition for opening the submenu
			const headerNode = this.root.querySelector(this.dataSelector('header'));
			this.onTransitionEnd(headerNode).then(() => {
				this.delegateSubmenu(target);
			});
		} else {
			this.delegateSubmenu(target);
			window.scrollTo(0, 0);
		}
	}

	delegateSubmenu(target) {
		const toggler = target;
		const mainMenuNavId = this.dataAttr(toggler).get(this.togglerIdAttribute);
		const togglerLabel = this.dataAttr(toggler).get(this.togglerLabelAttribute);
		this.layerNodes = this.element.querySelectorAll(this.dataSelector(this.subMenuLayerAttribute));

		this.SubMenuComponent.clearBreadcrumbList();
		this.SubMenuComponent.addToBreadcrumbList(togglerLabel);
		this.resetActiveToggler();
		this.setActiveToggler(toggler);

		for (const layerNode of this.layerNodes) {
			this.classList(layerNode).remove(this.visibleModifier);
			// if (this.classList(layerNode).contains(this.visibleModifier)) {
			// }

			const subMenuLayerId = this.dataAttr(layerNode).get(this.subMenuLayerIdAttribute);
			if (subMenuLayerId === mainMenuNavId) {
				if (!this.classList(this.element).contains(this.subMenuVisibleModifier)) {
					this.showSubmenu(layerNode);
				}
				this.classList(layerNode).add(this.visibleModifier);
			}
		}
	}


	showSubmenu(layerNode) {
		this.threeStateTransition(this.element).add(this.subMenuVisibleModifier);
	}


	hideSubmenu() {
		this.SubMenuComponent.clearBreadcrumbList();
		this.SubMenuComponent.closeDescendants();
		if (this.classList(this.element).contains(this.subMenuVisibleModifier)) {
			this.threeStateTransition(this.element).remove(this.subMenuVisibleModifier);
		}

		requestAnimationFrame(() => {
			const layerNodes = this.element.querySelectorAll(this.dataSelector(this.subMenuLayerAttribute));

			for (const layerNode of layerNodes) {
				const layerNodeClassList = this.classList(layerNode);
				layerNodeClassList.remove(this.visibleModifier);
			}
		});
	}

	setActiveToggler(toggler) {
		this.classList(toggler).add(this.activeModifier);
	}

	resetActiveToggler() {
		const togglers = this.mainMenuNavNode.querySelectorAll(this.dataSelector(this.togglerAttribute));

		for (const toggler of togglers) {
			this.classList(toggler).remove(this.activeModifier);
		}
	}

	setCurrentSubNavLevel(level) {
		this.currentSubNavLevel = level;
		this.dataAttr(this.element).set(this.currentSubNavLevelAttribute, level);
	}

	resetMenu() {
		this.setCurrentSubNavLevel(0);
		this.resetActiveToggler();
		this.hideSubmenu();
	}

	setAnimationFlag(isAnimating) {
		this.isAnimating = isAnimating;
	}
}


export default MainMenu;

class RemoteApiRepository {
	constructor({
		filterData,
		thumbUrl
	}) {
		this.filterData = filterData;
		this.thumbUrl = thumbUrl;
		this.dynamicFilterData = {};
		this.itemsCount = 0;
	}


	injectDriver(driver) {
		this.driver = driver;
		this.defaultOptions = {
			type: 'items',
			hasThumb: true,
			thumbSize: 'xLarge',
			assetId: 0
		};
	}

	injectDynamicTemplate(dynamicTemplate) {
		this.dynamicTemplate = dynamicTemplate;
	}


	prepareData(filterData, options) {
		const data = {
			filterData: filterData,
			options: Object.assign(this.defaultOptions, options)
		};

		return data;
	}


	getFilterData() {
		return this.filterData;
	}

	getThumbUrl() {
		return this.thumbUrl;
	}

	getDynamicFilterData() {
		return this.dynamicFilterData;
	}


	async request(data) {
		return this.driver.request('POST', '', data).then((response) => response.data);
	}


	async getItems(filterData) {
		this.filterData = filterData;
		const options = {
			type: 'items',
			hasThumb: false,
		};
		const data = this.prepareData(this.filterData, options);
		const response = this.request(data);
		this.itemsCount = response.itemsCount;
		return response;
	}


	async getFilterOptions() {
		const options = {
			type: 'filterParams',
			hasThumb: false,
		};

		const filterData = {
			limit: 0
		};

		const data = this.prepareData(filterData, options);
		this.dynamicFilterData = this.request(data);
		return this.dynamicFilterData;
	}
}


export default RemoteApiRepository;

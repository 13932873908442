import {html, nothing} from '../../../../common/tpl/tpl';
import {classes as c, dataAttr, uniqueId} from '../../../../common/tpl/directives';
import {ucFirst} from '../../../../common/utils/string';
import fieldActionsTpl from './field-actions';
import descriptionTpl from './field/description';
import errorsTpl from './field/errors';
import preFieldTpl from './field/pre-field';
import postFieldTpl from './field/post-field';
import sortableHandleTpl from './sortable-handle';

import checkboxTpl from './field/checkbox';
import hiddenTpl from './field/hidden';
import numberTpl from './field/number';
import radioTpl from './field/radio';
import selectTpl from './field/select';
import textTpl from './field/text';
import searchTpl from './field/search';
import textareaTpl from './field/textarea';
import groupTpl from './field/group';


export default (field, name, value, errors, flags = {}) => {
	const tplMap = {
		checkbox: checkboxTpl,
		hidden: hiddenTpl,
		filterNumber: numberTpl,
		radio: radioTpl,
		group: groupTpl,
		select: selectTpl,
		filterSearch: searchTpl,
		text: textTpl,
		textarea: textareaTpl
	};

	// console.log('flags received', name, flags, value);
	const id = uniqueId(name);
	field = Object.assign({
		name: name,
		inputName: id,
		id: id,
		type: 'text',
		value: ('default' in field ? field.default : ''),
		attributes: {},
		classes: [],
		modifiers: [],
		data: {},
		disabled: false,
		required: false,
		hidden: false,
		readonly: false
	}, field, flags, {errors: errors});

	// console.log('field', name, field.type, value);
	if (value !== undefined) {
		field.value = value;
	}

	if (!('filled' in field)) {
		field.filled = (field.value !== '');
	}
	if (field.filled) {
		field.modifiers.push('filled');
	}

	if (field.type === 'hidden') {
		return tplMap[field.type](field);
	}

	const classes = ['field', 'field--' + field.type].concat(field.classes).concat(field.modifiers.map((modifier) => 'field--' + modifier));
	if ('size' in field) {
		const size = Math.max(1, Math.min(12, +field.size));
		classes.push('field--colSpan' + size);
	}
	if (field.hidden) {
		classes.push('field--hidden');
	}
	if ('actions' in field) {
		classes.push('field--hasActions');
	}
	if (field.disabled) {
		classes.push('js-disabled');
		// entry.attributes.disabled = 'disabled';
	}
	classes.push(field.required ? 'field--required' : 'field--optional');

	const data = Object.assign({
		component: ucFirst(field.type) + 'Field',
		field: field.name,
		type: field.type
	}, field.data);

	if (field.type === 'decoration' || field.type === 'static') {
		delete data.field;
		delete data.component;
		data.decorationField = field.name;
	}
	if (field.type === 'group' || field.type === 'array') {
		data.fieldsGroup = field.name;
		if (field.type === 'array') {
			field.itemTemplate.type = 'group';
			if ('sortable' in field && field.sortable) {
				data.sortable = true;
			}
		} else {
			if ('sortableItem' in field && field.sortableItem) {
				classes.push('field--sortableItem');
			}
		}
	} else {
		data.default = ('default' in field ? field.default : '');
	}


	// const templatePath = 'form/field/' + field.type;
	if (!(field.type in tplMap)) {
		return html`<div>Template not found: ${field.type}`;
	}

	return html`
		<div class=${c(classes)} data=${dataAttr(data)}>
			<div class=${c('field__container')}>
				${preFieldTpl(field)}
				<div class=${c('field__content', ('actions' in field ? 'field__content--hasActions' : ''))}>
					${'sortableItem' in field && field.sortableItem ? sortableHandleTpl() : nothing}
					${tplMap[field.type](field)}
					${'actions' in field ? fieldActionsTpl(field.actions, field) : nothing}
				</div>
				${descriptionTpl(field)}
				${errorsTpl(field.errors ? field.errors : [])}
				${postFieldTpl(field)}
			</div>
		</div>
	`;
};

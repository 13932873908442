import SearchField from '../../../common/form/search-field';

class FilterSearchField extends SearchField {
	constructor({
		root,
		element,
		visibleModifier = 'visible',
		hiddenModifier = 'hidden'
	}) {
		super({root: root, element: element});
		this.break = false;
		this.debounceDelay = 500; //ms
		this.searchInputNode = null;
		this.visibleModifier = visibleModifier;
		this.hiddenModifier = hiddenModifier;
		this.ENTER_KEYCODE = 13;
	}


	initEvents() {
		this.clearButton = this.element.querySelector(this.dataSelector('clearButton'));
		this.searchInputNode = this.element.querySelector(this.dataSelector('searchInput'));
		this.helpLinkNode = this.element.querySelector(this.dataSelector('helpLink'));
		this.listeners.click = this.events.on(this.clearButton, 'click', this.onClearClick.bind(this));
		return super.initEvents();
	}

	onInput(event, target) {

		if (target.value.length > 0) {
			this.classList(this.clearButton).add(this.visibleModifier);
			this.classList(this.helpLinkNode).add(this.hiddenModifier);
		} else {
			this.classList(this.clearButton).remove(this.visibleModifier);
			this.classList(this.helpLinkNode).remove(this.hiddenModifier);
		}

		if (!this.break) {
			this.break = true;
			setTimeout(() => {
				this.triggerEvent('change', event, {value: target.value});
				this.break = false;
			}, this.debounceDelay);
		}
	}


	onClearClick(event, target) {
		this.classList(this.clearButton).remove(this.visibleModifier);
		this.classList(this.helpLinkNode).remove(this.hiddenModifier);
		this.searchInputNode.value = '';
		this.triggerEvent('change', event, {value: ''});
	}



}


export default FilterSearchField;

export default async () => {
	const blueprint = {
		name: 'searchField',
		inputName: 'searchFieldInput',
		id: 'search',
		type: 'filterSearch',
		value: '',
		linkUrl: 'https://emp-web-88.zetcom.ch/eMP/eMuseumPlus?service=WebAsset&url=helpText/search/HelpText_de.html&contentType=text/html',
		attributes: {
			placeholder: 'Suche'
		},
		classes: [],
		modifiers: [],
		data: {
			searchInput: null
		},
		disabled: false,
		required: false,
		hidden: false,
		readonly: false
	};

	return blueprint;
};

class PaginationItem {

	constructor({page = 0, url = '', status = {}})	{
		this.page = page;
		this.url = url;
		this.current = false;
		this.status = Object.assign({
			prev: false,
			next: false,
			last: false,
			current: false,
			link: false,
			separator: false
		}, status);
	}


	// useful for quick overview
	toString() {
		if (this.isPrev()) {
			return '<';
		}
		if (this.isNext()) {
			return '>';
		}
		if (this.isSeparator()) {
			return '...';
		}
		let out = String(this.getPage());
		if (this.isCurrent()) {
			out = '[' + out + ']';
		}
		return out;
	}


	getPage() {
		return this.page;
	}


	getStatus() {
		return this.status;
	}


	getUrl() {
		return this.url;
	}


	isPrev() {
		return this.status.prev;
	}


	isNext() {
		return this.status.next;
	}


	isFirst() {
		return this.status.first;
	}


	isLast() {
		return this.status.last;
	}


	isCurrent() {
		return this.status.current;
	}


	isLink() {
		return this.status.link;
	}


	isSeparator() {
		return this.status.separator;
	}

}


export default PaginationItem;

import {html, nothing} from '../../../../common/tpl/tpl';
import {img, classes, dataAttr, attr} from '../../../../common/tpl/directives';
import {isString} from '../../../../common/utils/types';

export default (file, options = {}) => {
	const params = img(file, options);
	const imgOptions = params.options;

	const blockClass = imgOptions.blockClass;
	if (!imgOptions.onlyImg && isString(blockClass) && blockClass.length > 0) {
		imgOptions.classes = imgOptions.classes.concat(blockClass, imgOptions.modifiers.map((modifier) => blockClass + '--' + modifier));
		imgOptions.imgClasses.push(blockClass + '__img');
		imgOptions.linkClasses.push(blockClass + '__link');
		imgOptions.captionClasses.push(blockClass + '__caption');
		imgOptions.imgWrapperClasses.push(blockClass + '__imgWrapper');
	}

	const link = (content) => {
		if (imgOptions.link) {
			return html`
				<a
					href=${imgOptions.link}
					class=${classes(imgOptions.linkClasses)}
					data=${dataAttr(imgOptions.linkData)}
					attr=${attr(imgOptions.linkAttributes)}
				>
					${content}
				</a>
			`;
		} else {
			return html`${content}`;
		}
	};


	const imgWrapper = (content) => {
		if (imgOptions.imgWrapper) {
			return html`
				<span class=${classes(imgOptions.imgWrapperClasses)}>
					${content}
				</span>
			`;
		} else {
			return html`
				${content}
			`;
		}
	};

	const imgMarkup =  (noscriptVersion = false) => html`
		<img
			class=${classes(imgOptions.imgClasses)}
			attr=${attr(imgOptions.imgAttributes)}
			alt=${imgOptions.alt ? imgOptions.alt : ''}
			sizes=${params.sizes}
			src=${imgOptions.fallbackUrl ? imgOptions.fallbackUrl : params.src}
			style=${imgOptions.fallbackUrl ? 'background-image: url("' + imgOptions.fallbackUrl + '")' : ''}
			data=${dataAttr((params.async && !noscriptVersion) ? Object.assign({}, imgOptions.imgData, {srcset: params.srcset}) : imgOptions.imgData)}
			srcset=${!params.async ? params.srcset : ''}
		/>
	`;

	const noscript = html`
		<noscript>
			${imgMarkup(true)}
		</noscript>
	`;


	const caption = html`
		<figcaption class=${classes(imgOptions.captionClasses)} data=${dataAttr(imgOptions.captionData)}>
			${imgOptions.caption}
		</figcaption>
	`;


	const imgBody = html`
		${imgOptions.srcAttr !== 'srcset' ? noscript : nothing}
		${imgMarkup()}
		${imgOptions.caption && !imgOptions.onlyImg ? caption : nothing}
	`;


	const figure = (content) => {
		if (!imgOptions.onlyImg) {
			return html`
				<figure
					class=${classes(imgOptions.classes)}
					data=${dataAttr(imgOptions.data)}
					attr=${attr(imgOptions.attributes)}
					style=${params.style}
				>
					${content}
				</figure>
			`;
		} else {
			return html`
				${content}
			`;
		}
	};


	return html`
		${link(figure(imgWrapper(imgBody)))}
	`;
};

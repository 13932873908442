import {directive} from 'lit-html';


const stateMap = new WeakMap();

const once = directive((value) => (part) => {
	if (!stateMap.get(part)) {
		stateMap.set(part, true);
		part.setValue(value);
	}
});

export default once;

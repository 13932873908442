import PageComponent from '../../common/component/page-component';

class SearchController extends PageComponent {

	constructor({
		root,
		element,
		inputAttribute = 'searchInput',
		inputResetAttribute = 'searchInputReset',
		suggestionSectionAttribute = 'searchSuggestions',
		visibleModifier = 'visible'
	}) {
		super({root: root, element: element});
		this.inputAttribute = inputAttribute;
		this.inputResetAttribute = inputResetAttribute;
		this.suggestionSectionAttribute = suggestionSectionAttribute;

		this.visibleModifier = visibleModifier;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		this.options = data.options;

		this.sugggestionSectionNode = this.element.querySelector(this.dataSelector(this.suggestionSectionAttribute));
		const inputNode = this.element.querySelector(this.dataSelector(this.inputAttribute));
		const innputResetNode = this.element.querySelector(this.dataSelector(this.inputResetAttribute));

		this.listeners.input = this.events.on(inputNode, 'input', this.onInput.bind(this));
		this.listeners.keyDown = this.events.on(inputNode, 'keydown', this.onKeydown.bind(this));
		this.listeners.click = this.events.on(innputResetNode, 'click', this.onClick.bind(this));
	}


	onInput(event, target) {
		const value = target.value;

		if (value.length > 0) {
			this.classList(this.sugggestionSectionNode).add(this.visibleModifier);
		} else {
			this.classList(this.sugggestionSectionNode).remove(this.visibleModifier);
		}
	}


	onClick() {
		this.classList(this.sugggestionSectionNode).remove(this.visibleModifier);
	}


	onKeydown(event, target) {
		if (event.keyCode === 27) {
			target.value = '';
			this.classList(this.sugggestionSectionNode).remove(this.visibleModifier);
		}
	}
}

export default SearchController;

const text = {
	get: (id, params = {}) => {
		if (!text.locale) {
			throw Error('Text provider not defined');
		}
		return text.locale.get(id, params);
	},

    translate: (lang, id, params = {}) => {
		if (!text.locale) {
			throw Error('Text provider not defined');
		}
		return text.locale.translate(lang, id, params);
	},

    format: (value, params = {}) => {
		if (!text.locale) {
			throw Error('Text provider not defined');
		}
		return text.locale.format(value, params);
    }

};


text.locale = null;

export default text;

import MainApp from './main-app';


export default (di) => {
	di
		.setType({
			type: MainApp, name: 'MainApp', parent: 'App', params: {
				requiredStyles: ['default'],
				requiredFonts: []
			}, setters: {
				injectHashScroller: di.lazyNew('HashScroller'),
			}
		})
		.set('app', di.lazyNew('MainApp'))
	;
};

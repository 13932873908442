import InteractiveField from './interactive-field';


class HiddenField extends InteractiveField {

	getInput() {
		return this.element;
	}


	writeValue(value) {
		const customType = this.dataAttr().get('customType', false);
		if (customType) {
			this.dataAttr().set('value', value);
		} else {
			this.getInput().value = value;
		}
	}


	readValue() {
		const customType = this.dataAttr().get('customType', false);
		return (customType ? this.dataAttr().get('value') : this.getInput().value);
	}


	canFocus() {
		return false;
	}


	setFocus() { }

	setBlur() {	}

	initEvents() { }

	enableEvents() { }

	disableEvents() { }

	deinitEvents() { }

	focus(value, suppressEvent = false) { }

	blur(value, suppressEvent = false) { }

	setErrors(errors = []) { }

}


export default HiddenField;

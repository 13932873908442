class Blueprints {
	constructor({remoteApiRepository, blueprints}) {
		this.remoteApiRepository = remoteApiRepository;
		this.blueprints = blueprints;
		this.value = null;
	}


	async get() {
		if (this.value === null) {
			this.value = await this.blueprints(this.remoteApiRepository);
		}
		return this.value;
	}

	set(blueprints) {
		this.blueprints = blueprints;
	}

}


export default Blueprints;

import commonConfig from './common';

import animationConfig from '../animation/_config';
import appConfig from '../app/_config';
import contentConfig from '../content/_config';
import contextConfig from '../context/_config';
import dynamicSectionConfig from '../dynamic-section/_config';
import formConfig from '../../common/form/_config';
import galleryConfig from '../gallery/_config';
import navigationConfig from '../navigation/_config';
import templateControllerConfig from '../template-controller/_config';

import dataAttr from '../../common/tpl/data-attr';
import classes from '../../common/tpl/classes';
import iconInfo from '../../common/tpl/icon-info';
import url from '../../common/tpl/url';
import img from '../../common/tpl/img';

const config = (di) => {
	commonConfig(di);

	animationConfig(di);
	appConfig(di);
	contentConfig(di);
	contextConfig(di);
	dynamicSectionConfig(di);
	formConfig(di);
	galleryConfig(di);
	navigationConfig(di);
	templateControllerConfig(di);

	const bodyData = di.get('dom/dataAttrFactory')({element: document.body});

	let baseUrl = bodyData.get('baseUrl');
	const apiUrl = 'museum-api-proxy';
	const itemsUrl = apiUrl + '/items';
	const thumbUrl = apiUrl + '/thumb';

	if (baseUrl.indexOf('http') !== 0) {
		baseUrl = location.protocol + '//' + location.host + baseUrl;
	}

	di
		.setValue('baseUrl', baseUrl)
		.setValue('itemsUrl', itemsUrl)
		.setValue('thumbUrl', thumbUrl)
		.setParam('HashScroller', 'duration', 0.5)
	;


	dataAttr.ns = di.getValue('dom/dataAttrPrefix', '');
	classes.ns = di.getValue('dom/baseClassPrefix', '');
	iconInfo.loader = di.get('preload/iconFilesLoader');

	url.baseUrl = baseUrl;
	url.baseMediaUrl = bodyData.get('baseMediaUrl');
	url.locale = di.get('locale/localeText');

	// eslint-disable-next-line piggyback/no-restricted-global-extend
	img.options.imagesInfo = window.imagesInfo;
	img.options.baseUrl = baseUrl;
	img.options.baseThumbUrl = bodyData.get('baseThumbUrl');
	img.options.sizes = {
		default: '100vw',
		cover: '50vw',
		filterOption: '25vw',
		article: '150px',
	};
};


export default config;

import {html} from '../../../common/tpl/tpl';
import {classes, when} from '../../../common/tpl/directives';
import filtersTpl from './blocks/filters';
import resultListTpl from './blocks/result-list';


export default (block) => {
	const blockName = block.name;
	const classesList = ['block'];
	classesList.push('block--' + blockName);

	return html`
		<div class=${classes(classesList)}>
			<div class=${classes('block__container')}>
				<div class=${classes('block__content')}>
					${when(blockName === 'filters', () => html `
						${filtersTpl(block.data)}
					`)}
					${when(blockName === 'resultList', () => html `
						${resultListTpl(block.data)}
					`)}
				</div>
			</div>
		</div>`;
};


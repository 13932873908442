import {seemsPlainObject} from './types';


// deep clone for json-like structure (it does not work with complex obj like Map and Set)
const deepClone = (obj) => {
	let cloned = obj;
	if (Array.isArray(obj)) {
		cloned = [];
		for (let i = 0; i < obj.length; i++) {
			cloned[i] = deepClone(obj[i]);
		}
	} else if (seemsPlainObject(obj)) {
		cloned = {};
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				cloned[key] = deepClone(obj[key]);
			}
		}
	}
	return cloned;
};


export default deepClone;

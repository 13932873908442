import domMixin from '../dom/dom-mixin';
import onTransitionEnd from '../dom/transition-end';
import {wait} from '../utils/wait';


class Notifications extends domMixin() {


	constructor({template, selector, tpl = 'notification', duration = 3 /*sec.*/, showClass = 'active'}) {
		super();
		this.template = template;
		this.tpl = tpl;
		this.duration = duration;
		this.showClass = showClass;
		this.promise = null;
		this.element = document.querySelector(selector);
	}


	notify(notifications) {
		if (!Array.isArray(notifications)) {
			notifications = [notifications];
		}
		if (!this.promise) {
			this.promise = Promise.resolve();
		}
		for (const notification of notifications) {
			const duration = ('duration' in notification ? notification.duration : this.duration);
			const tpl = ('template' in notification ? notification.template : this.tpl);
			const element = this.createNotificationElement(notification, tpl);
			this.element.appendChild(element);
			this.promise = this.promise.then(() =>
				wait(0) // we need to wait a frame after appending the element for the transition to take effect
			).then(() => {
				this.classList(element).add(this.showClass);
				return onTransitionEnd(element);
			}).then(() =>
				wait(duration)
			).then(() => {
				this.classList(element).remove(this.showClass);
				onTransitionEnd(element).then(() => {
					this.element.removeChild(element);
				});
				return Promise.resolve();
			});
		}
		return this.promise;
	}


	createNotificationElement(notification, tpl) {
		const div = document.createElement('div');
		div.innerHTML = this.template.render(tpl, notification);
		return div.querySelector('*');
	}

}


export default Notifications;

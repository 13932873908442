import DynamicSectionDefaultContent from './default';

/**
 * The markup of a section is loaded in the DOM only when the section is the current one,
 * so all the event listeners need to be delegated and node queries cannot be static
 *
 * @class DynamicSectionArtworksContent
 * @extends {DynamicSectionDefaultContent}
 */
class DynamicSectionArtworksContent extends DynamicSectionDefaultContent {
	injectPaginationItems(paginationItems) {
		this.paginationItems = paginationItems;
	}

	injectRemoteApiRepository(remoteApiRespository) {
		this.remoteApiRespository = remoteApiRespository;
	}

	init() {
		this.initListeners();
		this.listeners.click = this.events.on(this.dynamicTemplateElement, this.dataSelector('paginationButton'), 'click', this.onPaginationChange.bind(this));
	}

	onPaginationChange(event, target) {
		const targetAttr = this.dataAttr(target);
		const pageNumber = targetAttr.get('pageNumber');
		this.setItems(pageNumber);
	}


	setItems(pageNumber) {
		const filterData = this.remoteApiRespository.getFilterData();
		filterData.offset = filterData.limit * (pageNumber - 1);
		this.udpateItems(filterData);
	}

	udpateItems(filterData) {
		const hash = '#artworksResultList';
		this.events.trigger(document.body, 'history:hashchange', {hash: hash.substr(1)});
		if (history.pushState) {
			history.pushState(null, null, hash);
		} else {
			location.hash = hash;
		}
		this.dynamicTemplate.updateResultListState(false, true);
		this.remoteApiRespository.getItems(filterData).then((response) => {
			this.dynamicTemplate.updateItemsBlueprint(response, filterData);
		});
	}


}


export default DynamicSectionArtworksContent;

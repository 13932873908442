import {html} from '../../../../../common/tpl/tpl';
import {when} from '../../../../../common/tpl/directives';
import labelTpl from './label';
import groupsTpl from './radio-groups';
import optionsTpl from './radio-options';
import linkedImagesTpl from './radio-linked-images';


export default (field) => html`
	${labelTpl(field)}
	${when(field.data.filterVariant === 'linkedImages', () => linkedImagesTpl(field))}
	${when('groups' in field && field.groups.length, () => groupsTpl(field, field.groups), () => optionsTpl(field, field.options))}`;

import PageComponent from '../../common/component/page-component';
import {ucFirst} from '../../common/utils/string';


class SubMenu extends PageComponent {

	constructor({
		root,
		element,
		mainMenuAttributee = 'mainMenu',
		backButtonAttribute = 'subMenuBackButton',
		togglersAttribute = 'subMenuTogglers',
		togglerAttribute = 'subMenuToggler',
		layersAttribute = 'subMenuLayers',
		layerAttribute = 'subMenuLayer',
		mainlayerAttribute = 'subMenuMainLayerItem',
		levelAttribute = 'subMenuLevel',
		togglerIdAttribute = 'subMenuTogglerId',
		togglerLabelAttribute = 'subMenuTogglerLabel',
		layerIdAttribute = 'subMenuLayerId',
		breadcrumbAttribute = 'breadcrumb',
		closingButtonAttribute = 'closingButton',

		visibleModifier = 'visible',
		activeModifier = 'active',
	}) {
		super({root: root, element: element});
		this.togglerAttribute = togglerAttribute;
		this.togglersAttribute = togglersAttribute;
		this.backButtonAttribute = backButtonAttribute;
		this.layersAttribute = layersAttribute;
		this.togglerIdAttribute = togglerIdAttribute;
		this.layerIdAttribute = layerIdAttribute;
		this.levelAttribute = levelAttribute;
		this.layerAttribute = layerAttribute;
		this.mainlayerAttribute = mainlayerAttribute;
		this.mainMenuAttributee = mainMenuAttributee;
		this.breadcrumbAttribute = breadcrumbAttribute;
		this.togglerLabelAttribute = togglerLabelAttribute;
		this.closingButtonAttribute = closingButtonAttribute;
		this.activeModifier = activeModifier;
		this.visibleModifier = visibleModifier;

		this.breadcrumbList = [];
		this.currentLevel = 0;
		this.isAnimating = false;
	}


	prepare() {
		this.MainMenuComponent = this.components.queryComponent(this.root, this.dataSelector('mainMenu'));
		this.breadcrumbNode = this.element.querySelector(this.dataSelector(this.breadcrumbAttribute));

		this.initEvents();
	}

	initEvents() {
		const layerNodes = this.element.querySelectorAll(this.dataSelector(this.mainlayerAttribute));
		const backButtonNode = this.element.querySelector(this.dataSelector(this.backButtonAttribute));
		const closingButtonNode = this.element.querySelector(this.dataSelector(this.closingButtonAttribute));

		this.listeners.backButtonClick = this.events.on(backButtonNode, 'click', this.onBackButtonClick.bind(this));
		this.listeners.closingButtonClick = this.events.on(closingButtonNode, 'click', this.onClosingButtonClick.bind(this));

		for (const layerNode of layerNodes) {
			const togglersNodes = layerNode.querySelectorAll(this.dataSelector(this.togglersAttribute));
			this.listeners.togglerClick = this.events.on(togglersNodes, this.dataSelector(this.togglersAttribute), 'click', this.onTogglerClick.bind(this));
		}
	}


	onClosingButtonClick() {
		if (!this.isAnimating) {
			if (this.contexts.getCurrentContext().name === 'menu') {
				this.contexts.pop();
				// this.resetActiveTogglers();
			}
		}
	}


	onBackButtonClick() {
		if (!this.isAnimating) {
			this.closeDescendants(this.currentLevel);
			this.popBreadcumbList();

			if (this.currentLevel > 0) {
				this.currentLevel--;
				this.MainMenuComponent.setCurrentSubNavLevel(this.currentLevel);
			} else {
				this.MainMenuComponent.hideSubmenu();
			}
		}
	}


	onTogglerClick(event, target) {
		if (!this.isAnimating) {
			const togglersNodes = target;
			const toggler = event.target.closest(this.dataSelector(this.togglerAttribute));
			if (toggler === null) return;

			const togglersLevel = this.dataAttr(togglersNodes).get(this.levelAttribute);
			const layersNodes = this.element.querySelectorAll(this.dataSelector(this.layersAttribute));
			const togglerId = this.dataAttr(toggler).get(this.togglerIdAttribute);

			this.delegateBreadcrumb(toggler, togglersLevel);

			// find the layers that have the same level of the toggler
			for (const layersNode of layersNodes) {
				const layersLevel = this.dataAttr(layersNode).get(this.levelAttribute);

				if (togglersLevel < layersLevel || layersLevel === togglersLevel - 1) {
					this.resetActiveTogglers(layersNode);
				}

				if (layersLevel === togglersLevel) {
					this.currentLevel = togglersLevel;
					this.MainMenuComponent.setCurrentSubNavLevel(this.currentLevel);

					this.setAnimationClasses(layersNode, this.visibleModifier);
					this.setAnimationClasses(togglersNodes, this.activeModifier);

					const layerNodes = layersNode.querySelectorAll(this.dataSelector(this.layerAttribute));

					for (const layerNode of layerNodes) {
						const layerId = this.dataAttr(layerNode).get(this.layerIdAttribute);

						const layerClassList = this.classList(layerNode);

						layerClassList.remove(this.visibleModifier);

						if (layerId === togglerId) {
							window.scrollTo(0, 0);

							const togglerClassList = this.classList(toggler);
							togglerClassList.add(this.activeModifier);
							layerClassList.add(this.visibleModifier);
						}
					}
				}
			}
		}
	}


	delegateBreadcrumb(toggler, togglersLevel) {
		const togglerLabel = this.dataAttr(toggler).get(this.togglerLabelAttribute);

		if (togglersLevel !== this.currentLevel) {
			this.addToBreadcrumbList(togglerLabel);
		} else {
			this.popBreadcumbList();
			this.addToBreadcrumbList(togglerLabel);
		}
	}


	addToBreadcrumbList(label) {
		this.breadcrumbList.push(label);
		this.updateBreadcrumbNode();
	}


	popBreadcumbList() {
		this.breadcrumbList.pop();
		this.updateBreadcrumbNode();
	}


	clearBreadcrumbList() {
		this.breadcrumbList = [];
	}


	updateBreadcrumbNode() {
		let breadcrumbString = '';

		for (let i = 0; i < this.breadcrumbList.length; i++) {
			const breadcrumbItem = this.breadcrumbList[i];
			breadcrumbString += i > 0 ? ' / ' + breadcrumbItem : breadcrumbItem;
		}

		this.breadcrumbNode.innerHTML = breadcrumbString;
	}


	resetActiveTogglers(node = this.element) {
		// reset all active togglers
		const togglerNodes = node.querySelectorAll(this.dataSelector(this.togglerAttribute));

		for (const togglerNode of togglerNodes) {
			this.classList(togglerNode).remove(this.activeModifier);
		}
	}


	setAnimationFlag(isAnimating) {
		this.isAnimating = isAnimating;
		this.MainMenuComponent.setAnimationFlag(isAnimating);
	}


	setAnimationClasses(node, modifier) {
		const nodeClassList = this.classList(node);
		const beforeModifier = 'before' + ucFirst(modifier);
		const duringModifier = 'during' + ucFirst(modifier);

		if (!nodeClassList.contains(modifier) || !nodeClassList.contains(beforeModifier) || !nodeClassList.contains(duringModifier)) {
			this.setAnimationFlag(true);
			this.threeStateTransition(node).add(modifier).then(() => {
				this.setAnimationFlag(false);
			});
		}
	}


	removeAnimationClasses(node, modifier) {
		const nodeClassList = this.classList(node);
		const beforeModifier = 'before' + ucFirst(modifier);
		const duringModifier = 'during' + ucFirst(modifier);

		if (nodeClassList.contains(modifier) || nodeClassList.contains(beforeModifier) || nodeClassList.contains(duringModifier)) {
			this.setAnimationFlag(true);
			this.threeStateTransition(node).remove(modifier).then(() => {
				this.setAnimationFlag(false);
			});
		}
	}


	closeDescendants(level = 1) {
		const layerNodes = this.element.querySelectorAll(this.dataSelector(this.layerAttribute));
		for (const layerNode of layerNodes) {
			const layerItemLevel = this.dataAttr(layerNode).get(this.levelAttribute);

			if (layerItemLevel ===  level) {
				this.resetActiveTogglers(layerNode);
				const layersNodes = layerNode.querySelectorAll(this.dataSelector(this.layersAttribute));
				const togglersNodes = layerNode.querySelectorAll(this.dataSelector(this.togglersAttribute));

				for (const layersNode of layersNodes) {
					this.removeAnimationClasses(layersNode, this.visibleModifier);

				}

				for (const togglersNode of togglersNodes) {
					this.removeAnimationClasses(togglersNode, this.activeModifier);

				}
			}
		}
	}
}


export default SubMenu;

import {html, nothing} from '../../../../../common/tpl/tpl';
import {classes, dataAttr, attr, once, repeat, when, uniqueId} from '../../../../../common/tpl/directives';
import {isString} from '../../../../../common/utils/types';
import panelSelectorLabelTpl from './panel-selector-label';
import constructItemTpl from './construct-item';
import img from '../../media/img';



export default (field, options) => html`
	<div class=${classes('field__inputWrapper')}>
		${repeat(options ? options : [], (option) => {
			const label = option.label;
			const value = option.value;
			const optionId = uniqueId(field.id);
			const data = 'data' in option ? option.data : {};
			const attributes = Object.assign({}, 'attributes' in option ? option.attributes : {});
			attributes['.checked'] = (String(field.value) === String(value));
			return html`
				<div class=${classes(['field__inputOption'].concat((option.modifiers || []).map((modifier) => 'field__inputOption--' + modifier)))}>
					<input type="radio"
						name=${once(field.inputName)}
						id=${once(optionId)}
						class=${classes('field__input', 'field__input--radio')}
						attr=${attr(Object.assign({}, attributes, {value: value, '.disabled': (field.disabled || option.disabled)}))}
						data=${dataAttr(data)} />

					<label class=${classes('field__label', 'field__label--inputLabel')} for=${once(optionId)}>
						<span class=${classes('field__labelValue')}>
							${when(isString(label), label, () => {
								const customTpls = {
									panelSelector: panelSelectorLabelTpl,
									constructItem: constructItemTpl
								};

								if ('template' in label && label.template in customTpls) {
									return customTpls[label.template](label);
								}
								return nothing;
							})}
						</span>
						${when('image' in data && data.image, () => html`
							<span class=${classes('field__labelImage')}>
								${img(data.image, data.imageOptions || {})}
							</span>
						`)}

					</label>
				</div>`;
		})}
	</div>`;

import {html} from '../../../../common/tpl/tpl';
import {classes, dataAttr} from '../../../../common/tpl/directives';
import icon from './../media/icon';
import fieldsTpl from './../form/fields';
import fieldTpl from './../form/field';

export default (blueprint, values = null) => {

	const fields = blueprint.fields;
	const idPrefix = 'accordion-filter-';

	const render = !!values;
	const data = {
		component: 'FilterForm',
		filterForm: '',
		fieldsGroup: null,
		blueprint: blueprint,
		rendered: render,
	};

	const accordionToggler = (id, label) => html`
		<dt class=${classes('accordion__toggler')}>
			<a
				href="#${id}"
				class=${classes('accordion__togglerLink')}
				data=${dataAttr({component: 'Toggler'})}
				aria-expanded="false"
			>
				<span class=${classes('accordion__togglerLabel')}>${label}</span>
			</a>
			<span class=${classes('accordion__togglerIcon')}>${icon('24-close')}</span>
		</dt>
	`;

	const accordionContent = (id, content) => html`
		<dd class=${classes('accordion__contentWrapper')}>
			<div
				id="${id}"
				class=${classes('accordion__content', 'js-collapsed')}
				aria-hidden="true"
				data=${dataAttr({component: 'Collapsable'})}
				tabindex="-1"
			>
				${content}
			</div>
		</dd>
	`;


	return html`
		<form class=${classes('form')} data=${dataAttr(data)}>
			<ul class=${classes('filters')}>

				${fields.map((field, index) => {
					const label = field.label;
					const type = field.type;
					const fieldData = field.fieldData;
					const id = idPrefix + index;
					const collapsable = field.collapsable;
					const hiddenOnMobile = field.hiddenOnMobile;
					const fieldList = type === 'single' ? [fieldData] : fieldData;

					if (collapsable) {
						return html`
							<div class=${classes('filters__item', hiddenOnMobile ? 'js-mobile-hidden' : '', field.itemClass && field.itemClass.length > 0 ? 'filters__item--' + field.itemClass : '')}>
								<div class=${classes('accordion')}>
									${accordionToggler(id, label)}
									${accordionContent(id, fieldsTpl(fieldList, [], {type: type}))}
								</div>
							</div>
						`;
					} else {
						return html`
							<div class=${classes('filters__item')}>
								${fieldTpl(fieldData)}
							</div>
						`;
					}
				})}
			</ul>
		</form>
	`;
};

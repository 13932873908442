import {directive, TemplateResult, nothing} from 'lit-html';

const when = directive((condition, trueValue, elseValue = nothing) => (part) => {

	if (condition instanceof Function) {
		condition = condition();
	}
	let newValue = (condition ? trueValue : elseValue);
	if (newValue !== nothing && newValue instanceof Function && !(newValue instanceof TemplateResult)) {
		newValue = newValue();
	}

	part.setValue(newValue);
});

export default when;

import SubMenu from './sub-menu';
import MainMenu from './main-menu';


export default (di) => {
	di
		.setType({type: SubMenu, name: 'SubMenu', parent: 'PageComponent'})
		.setType({type: MainMenu, name: 'MainMenu', parent: 'PageComponent'})
	;
};

import PageComponent from '../component/page-component';


class ContentSliderNavigation extends PageComponent {
	constructor({root, element,	navigationAttribute = 'goTo', enabledClass = 'enabled', busyClass = 'busy'}) {
		super({root: root, element: element});
		this.defaults.navigationAttribute = navigationAttribute;
		this.defaults.enabledClass = enabledClass;
		this.defaults.busyClass = busyClass;
		this.defaults.for = '';

		this.busy = false;
		this.buttons = new Map();
	}


	prepare() {
		const data = this.dataAttr().getAll();
		this.navigationAttribute = data.navigationAttribute;
		for (const button of this.element.querySelectorAll(this.dataSelector(this.navigationAttribute))) {
			const dir = this.dataAttr(button).get(this.navigationAttribute);
			this.buttons.set(dir, button);
		}
		this.sliderId = data.for;
		if (this.sliderId !== '') {
			this.slider = this.components.queryComponent(this.root, this.dataSelector('id', this.sliderId));
			if (this.slider) {
				this.listeners.click = this.events.on(this.element, this.dataSelector(this.navigationAttribute), 'click', this.onClick.bind(this));
				this.listeners.update = this.events.on(this.slider.getElement(), this.slider.dataAttr().get('updateEvent'), this.onSliderUpdate.bind(this));
				this.enabledClass = data.enabledClass;
				this.busyClass = data.busyClass;
				this.update(this.slider.getStatus());
			}
		}
	}


	onClick(event, target) {
		event.preventDefault();
		event.stopPropagation();
		if (!this.busy) {
			this.busy = true;
			const dir = this.dataAttr(target).get(this.navigationAttribute);
			this.slider.stepTo(dir).then(() => {
				this.busy = false;
			});
		}
	}


	onSliderUpdate(event) {
		this.update(event.detail);
	}


	update(status) {
		for (const [dir, button] of this.buttons) {
			let enabled = false;
			if (status.running) {
				if (status.directions.x && (dir === 'left' || dir === 'right')) {
					enabled = (dir === 'left' && status.x < status.boundX[1]) || (dir === 'right' && status.x > status.boundX[0]);
				} else if (status.directions.y && (dir === 'top' || dir === 'bottom')) {
					enabled = (dir === 'top' && status.y < status.boundY[1]) || (dir === 'bottom' && status.y > status.boundY[0]);
				}
			}
			this.classList(button).toggle(this.enabledClass, enabled);
		}
	}

}

export default ContentSliderNavigation;

import PageComponent from '../../common/component/page-component';
import formConstants from '../../common/form/_constants';

class FilterForm extends PageComponent {
	constructor({
		element,
		root,
	}) {
		super({root: root, element: element});
		this.filterData = {};
		this.dynamicSection = null;
	}


	async prepare() {
		this.filterData = this.remoteApiRespository.getFilterData();
		this.dynamicSection = this.components.getComponent(this.root, 'DynamicSection');
		this.listeners.fieldChange = this.events.on( this.element, this.dataSelector(formConstants.fieldSelectorAttribute), 'field:change', this.onFieldChange.bind(this));
		this.listeners.submit = this.events.on( this.element, 'submit', this.onSubmit.bind(this));
	}

	injectRemoteApiRepository(remoteApiRespository) {
		this.remoteApiRespository = remoteApiRespository;
	}


	onFieldChange(event, target) {
		const field = event.detail.component;
		let value = event.detail.value;
		const type = field.getType();
		this.filterData.offset = 1;

		if (type === 'searchField') {
			this.filterData.searchStr = value;
			this.updateResult(this.filterData);

		}

		if (type === 'artistsField') {
			this.filterData.artistId = parseInt(value, 10);
			this.updateResult(this.filterData);
		}

		if (type === 'dateField') {
			const numberFieldsNode = this.element.querySelectorAll(this.dataSelector('field', type) + ' input');
			const range = [];
			numberFieldsNode.forEach((fieldNode) => {
				value = fieldNode.value ? parseInt(fieldNode.value, 10) : 0;
				range.push(value);
			});

			if (range[1] !== 0 && range[0] < range[1]) {
				this.filterData.date = range;
				this.updateResult(this.filterData);
			}
		}

		if (type === 'sortField') {
			this.filterData.sort = value;
			this.updateResult(this.filterData);
		}

		if (type === 'classificationField') {
			const classifications = [];
			const checkboxNodes = this.element.querySelectorAll(this.dataSelector('field', type) + ' input');
			checkboxNodes.forEach((checkboxNode) => {
				const isChecked = checkboxNode.checked;

				if (isChecked) {
					classifications.push(checkboxNode.value);
				}
			});
			this.filterData.classifications = classifications;
			this.updateResult(this.filterData);
		}

		if (type === 'layoutField') {
			const layout = value;
			this.dynamicSection.updateResultListLayout(layout);
		}

	}


	updateResult(filterData) {
		this.dynamicSection.updateResultListState(false, true);
		this.remoteApiRespository.getItems(filterData).then((response) => {
			const itemsData = response;
			this.dynamicSection.updateItemsBlueprint(itemsData, filterData).then(() => {
				this.dynamicSection.updateResultListState(false, false);
			});
		});
	}


	onSubmit(event) {
		event.preventDefault();
		return false;
	}
}


export default FilterForm;

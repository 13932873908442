import {html} from '../../../../../common/tpl/tpl';
import {classes} from '../../../../../common/tpl/directives';
import icon from '../../media/icon';


export default (params) => html`
	<span class=${classes('configuratorConstructItem')}>
		<span class=${classes('configuratorConstructItem__icon')}>${icon(params.icon)}</div>
	</span>
`;

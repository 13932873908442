import FontChecker from './font-checker';
import StyleChecker from './style-checker';
import IconFilesLoader from './icon-files-loader';


export default (di) => {
	di
		.setType({type: FontChecker, name: 'FontChecker'})
		.setType({type: StyleChecker, name: 'StyleChecker', mixins: ['domMixin']})
		.setType({type: IconFilesLoader, name: 'IconFilesLoader'})
		.set('preload/fontChecker', di.lazyNew('FontChecker'))
		.set('preload/styleChecker', di.lazyNew('StyleChecker'))
		.set('preload/iconFilesLoader', di.lazyNew('IconFilesLoader'))
	;
};

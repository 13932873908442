import {text, url} from '../../../../common/tpl/directives';
import map from '../../../../common/tpl/map';
import buttonTpl from '../button';


export default (action, params = {}) => {
	action = Object.assign({}, action);
	const attributes = Object.assign({}, ('attributes' in action ? action.attributes : {}));
	let data = Object.assign({}, ('data' in action ? action.data : {}));
	if ('href' in attributes) {
		attributes.href = url.get(attributes.href, params);
	}
	for (const name in data) {
		if (data.hasOwnProperty(name)) {
			data[name] = text.format(data[name], params);
		}
	}
	data = map(data, (value) => text.format(value, params), true);
	return buttonTpl(action, attributes, data);
};

export default {
	fieldSelectorAttribute: 'field',
	fieldsSelectorAttribute: 'fields',
	groupAttribute: 'fieldsGroup',
	nameAttribute: 'field',
	typeAttribute: 'type',
	defaultAttribute: 'default',
	errorsSelectorAttribute: 'fieldErrors',
	conditionalAttribute: 'conditions',
	disabledClass: 'disabled',
	hiddenClass: 'hidden'
};

import ImageSlide from './image-slide';


class ZoomableImageSlide extends ImageSlide {

    constructor({
		root,
		element,
		autoload = false,
		loadedClass = 'loaded',
		defaultDuration = 1,
		type = 'zoomableImage',
		smallImageAttribute = 'smallPicture',
		largeImageAttribute = 'largePicture'
	}) {
		super({root: root, element: element, type: type, autoload: autoload, loadedClass: loadedClass, defaultDuration: defaultDuration});
		this.smallImageAttribute = smallImageAttribute;
		this.largeImageAttribute = largeImageAttribute;
		this.largeLoading = false;
		this.largeLoaded = false;
		this.largeImage = null;
    }


    prepare() {
		this.openAsyncEvent('loadLarge');
		super.prepare();
    }


    getImage() {
		if (!this.image) {
			this.image = this.queryComponent(this.dataSelector(this.smallImageAttribute));
		}
		return this.image;
	}


    getLargeImage() {
		if (!this.largeImage) {
			this.largeImage = this.queryComponent(this.dataSelector(this.largeImageAttribute));
		}
		return this.largeImage;
	}


    loadLarge() {
		if (!this.largeLoading && !this.largeLoaded) {
			this.largeLoading = true;
			const image = this.getLargeImage();
			const loaded = () => {
				this.largeLoaded = true;
				this.closeAsyncEvent('loadLarge');
				if (this.element) {
					this.events.trigger(this.element, 'slide:loadlarge', {component: this});
				}
				this.largeLoading = false;
			};
			if (!image) {
				loaded();
			} else {
				image.load().then(() => loaded());
			}
		}
        return this.on('loadLarge');
    }


    setCurrent(value = true) {
		const shouldLoadLarge = value && !this.current;
		super.setCurrent(value);
		if (shouldLoadLarge) {
			this.loadLarge();
		}
        return this;
	}

}

export default ZoomableImageSlide;

import TextField from '../../../common/form/text-field';

class FilterNumberField extends TextField {
	constructor({
		root, element
	}) {
		super({root: root, element: element});
		this.maxChars = 4;
		this.break = false;
	}


	initEvents() {
		this.listeners.input = this.events.on(this.element, 'input', this.onInput.bind(this));
		return super.initEvents();
	}


	onInput(event, target) {
		const searchInput = target.value;

		if(searchInput.length > this.maxChars) {
			target.value = searchInput.substr(0, this.maxChars);
		}

		if (searchInput > 0) {
			if (!this.break) {
				this.break = true;
				setTimeout(() => {
					this.triggerEvent('change', event, {value: searchInput});
					this.break = false;
				}, 500);
			}
		}
	}
}


export default FilterNumberField;

import Context from '../../common/context/context';


class ModalContext extends Context {

	constructor({
		name,
		root,
		popOnKey = 'esc',
		fixedClass = 'fixed',
		activeClass = 'active',
		headerAttribute = 'header',
		isTransitioningAttribute = 'isTransitioning',
		mainMenuAttribute = 'mainMenu'
	}) {
		super({name: name, root: root, popOnKey: popOnKey});
		this.activeClass = activeClass;
		this.fixedClass = fixedClass;
		this.headerAttribute = headerAttribute;
		this.mainMenuAttribute = mainMenuAttribute;
		this.isTransitioningAttribute = isTransitioningAttribute;
		this.modalListener = null;
	}


	getLevel() {
		return 1;
	}


	beforeSwitchIn(otherContext) {
		return otherContext;
	}


	switchIn(otherContext) {
		const element = this.getElement();
		const headerNode = this.root.querySelector(this.dataSelector(this.headerAttribute));
		const CollapsingHeaderComponent = this.components.queryComponent(this.root, this.dataSelector(this.headerAttribute));
		CollapsingHeaderComponent.updateSize();
		this.onTransitionEnd(headerNode).then(() => {
			this.threeStateTransition(element).add(this.activeClass);
		});
		window.scrollTo(0, 0);
		this.dataAttr(this.root).set(this.isTransitioningAttribute, false);

		return otherContext;
	}


	switchOut(otherContext) {
		this.dataAttr(this.root).set(this.isTransitioningAttribute, true);
		const element = this.getElement();
		const descendantListContent = this.element.querySelector(this.dataSelector('component', 'ContentOverlayController'));

		if (descendantListContent) {
			const id = this.dataAttr(descendantListContent).get('id');
			const descendantListToggler = this.root.querySelector(this.dataSelector('for', id));
			this.classList(descendantListToggler).remove('toggled');
			descendantListContent.parentNode.removeChild(descendantListContent);
		}
		this.classList(element).add(this.fixedClass);
		if (this.linkListener) {
			this.linkListener.destroy();
		}

		if (this.modalListener) {
			this.modalListener.destroy();
		}


		const CollapsingHeaderComponent = this.components.queryComponent(this.root, this.dataSelector(this.headerAttribute));
		this.threeStateTransition(element).remove(this.activeClass).then(() => {
			this.classList(element).remove(this.fixedClass);
			this.onTransitionEnd(element).then(() => {
				CollapsingHeaderComponent.updateSize();
				this.dataAttr(this.root).set(this.isTransitioningAttribute, false);
			});
			return otherContext;
		});
		return otherContext;
	}

}


export default ModalContext;

// import {directive, TemplateResult, nothing} from 'lit-html';
import {repeat as litRepeat} from 'lit-html/directives/repeat';

const repeat = (items, keyFn, template) => {
	if (items instanceof Map || (
		typeof items === 'object' &&
		items !== null &&
		items.constructor === Object &&
		Object.prototype.toString.call(items) === '[object Object]'
	)) {
		// console.log('faking iterable');
		const entries = (items instanceof Map || items instanceof Set ? items : Object.entries(items));
		const newKeyFn = (item, index) => keyFn(item[1], item[0]);
		const newTemplate = template ? (item, index) => template(item[1], item[0]) : template;
		return litRepeat(entries, newKeyFn, newTemplate);
	}

	return litRepeat(items, keyFn, template);
};

export default repeat;

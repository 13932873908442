export default async (remoteApiRepository) => {
	const blueprint = [
		{
			name: 'dateField',
			inputName: 'dateFieldFrom',
			id: 'dateInput',
			type: 'filterNumber',
			value: 'from',
			attributes: {
				placeholder: 'YYYY',
				step: 1,
				min: 0,
				max: 9999
			},
			classes: [],
			modifiers: [],
			data: {},
			options: [],
			disabled: false,
			required: false,
			hidden: false,
			readonly: false,
			groupLabel: 'bis'
		},
		{
			name: 'dateField',
			inputName: 'dateFieldTo',
			id: 'dateInput',
			type: 'filterNumber',
			value: 'to',
			attributes: {
				placeholder: 'YYYY',
				step: 1,
				min: 0,
				max: 9999
			},
			classes: [],
			modifiers: [],
			data: {},
			options: [],
			disabled: false,
			required: false,
			hidden: false,
			readonly: false,
			groupLabel: ''
		}
	];

	return blueprint;
};

import {html} from '../../../../../common/tpl/tpl';
import {classes, when} from '../../../../../common/tpl/directives';
import img from '../../media/img';



export default (field) => {
	const style = ('imagesRatio' in field.data ? `--heightRatioPerc: ${Math.round(1 / field.data.imagesRatio * 1000000) / 10000}%;` : '');
	const options = ('groups' in field && field.groups.length ? field.groups.reduce((result, group) => result.concat(group.options), []) : field.options);
	let selectedImage = null;
	for (const option of options) {
		if (String(field.value) === String(option.value)) {
			selectedImage = option.data.imagePath;
			break;
		}
	}

	return html`
		<div class=${classes('field__linkedImage')} style=${style}>
			${when(field.data.bgImage, () => html`
				${img(field.data.bgImage, {sizes: 'filterOption', modifiers: ['filterLinkedBgImage']})}
			`)}
			${when(selectedImage, () => html`
				${img(selectedImage, {sizes: 'filterOption', modifiers: ['filterLinkedImage']})}
			`)}
		</div>`;
};

import autosize from 'autosize';
import InteractiveField from './interactive-field';
import textFieldMixin from './text-field-mixin';


class TextareaField extends textFieldMixin(InteractiveField) {

	prepare() {
		super.prepare();
		autosize(this.getInput());
	}


	getInput() {
		if (!this.input) {
			this.input = this.element.querySelector('textarea');
		}
		return this.input;
	}


	writeValue(value) {
		super.writeValue(value);
		autosize.update(this.getInput());
		return this;
	}


}


export default TextareaField;

import {html} from '../../../../../common/tpl/tpl';
import {classes, dataAttr, attr, once} from '../../../../../common/tpl/directives';
import labelTpl from './label';
import icon from '../../media/icon';


export default (field) => html`
	${labelTpl(field)}
	<div class=${classes('field__inputWrapper')}>
		<input type="text"
			name=${once(field.inputName)}
			id=${once(field.id)}
			class=${classes('field__input', 'field__input--text', 'field__input--search')}
			data=${dataAttr('data' in field ? field.data : {})}
			attr=${attr(Object.assign({}, field.attributes, {'.value': field.value, '?readonly': field.readonly, '.disabled': field.disabled}))} />
		<span class=${classes('field__searchIcon')} data=${dataAttr({searchIcon: null})}>${icon('16-search')}</span>
		<a href="${field.linkUrl}" class=${classes('field__helpLink')} attr="${attr({target: '_blank'})}" data="${dataAttr({helpLink: null})}">
			<span class=${classes('field__helpLinkLabel')}>Suchhilfe</span>
		</a>
		<a class=${classes('field__clearLink')} data=${dataAttr({clearButton: null})} role="button">${icon('24-close')}</a>

	</div>`;

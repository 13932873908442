import {render} from '../../common/tpl/tpl';
import ApiDriver from './api-driver';
import Blueprints from './blueprints';
import DynamicTemplate from './dynamic-template';
import DynamicTemplateSection from './dynamic-template-section';
import filterData from './../dynamic-section/filter-data';

import RemoteApiRepository from './remote-api-repository';

export default (di) => {
	di
		.setType({
			type: DynamicTemplate,
			name: 'DynamicTemplate',
			parent: 'PageComponent',
			mixins: ['domMixin', 'listenerMixin'],
			params: {
				renderer: render
			},
			setters: {
				injectContentFactory: di.lazyNew('Factory', {suffix: 'DynamicTemplateSection'}),
				injectRemoteApiRepository: di.lazyGet('templateController/remoteApiRepository'),
			}
		})

		.setType({
			type: DynamicTemplateSection,
			name: 'DynamicTemplateSection',
			mixins: ['domMixin', 'listenerMixin'],
		})
		.setType({
			type: Blueprints,
			name: 'Blueprints',
		})
		.set('templateController/blueprints', di.lazyNew('Blueprints'))

		// handles the xml communication via the apiDriver
		.setType({
			type: RemoteApiRepository,
			name: 'RemoteApiRepository',
			params: {
				filterData: filterData,
				thumbUrl: di.lazyValue('thumbUrl')
			},
			setters: {
				injectDriver: di.lazyGet('templateController/apiDriver'),
			}
		})
		.set('templateController/remoteApiRepository', di.lazyNew('RemoteApiRepository'))

		.setType({
			type: ApiDriver,
			name: 'ApiDriver',
		})
		.set('templateController/apiDriver', di.lazyNew('ApiDriver', {
			itemsUrl: di.lazyValue('itemsUrl'),
		}))

	;
};

// import coverPanel from '../panel/projects-cover';
import filters from '../blocks/filters';
import resultList from '../blocks/result-list';

export default async (remoteApiRepository) => {
	const blueprint = {
		name: 'artworks',
		label: 'artworks section',
		enabled: true,
		blocks: [
			{
				name: 'filters',
				data: await filters(remoteApiRepository)
			}, {
				name: 'resultList',
				data: await resultList(remoteApiRepository)
			}
		]
	};

	return blueprint;
};

import formConstants from './_constants';


const baseFieldMixin = (Base = class Empty {}) => class extends Base {


	injectTemplate(template) {
		this.template = template;
	}


	getName() {
		return this.dataAttr().get(formConstants.nameAttribute);
	}


	getType() {
		return this.dataAttr().get(formConstants.nameAttribute);
	}


	getDefaultValue() {
		return this.dataAttr().get(formConstants.defaultAttribute);
	}



	getPath() {
		const fieldElement = this.getElement();
		const path = [];
		let group = fieldElement;
		do {
			group = group.parentNode.closest(this.dataSelector(formConstants.groupAttribute));
			if (group) {
				const groupName = this.dataAttr(group).get(formConstants.groupAttribute);
				if (groupName) {
					path.push(groupName);
				} else {
					break;
				}
			}
		} while (group);
		path.reverse();
		path.push(this.getName());
		return path;
	}


	canFocus() {
		return false;
	}


	getValue() {
		return this.readValue();
	}



	resetValue() {
		this.setValue(this.getDefaultValue());
	}


	resetErrors() {
		this.setErrors([]);
	}


	reset() {
		this.resetValue();
		this.resetErrors();
	}


	setValue(value) {
		this.writeValue(value);
	}


	setValueAndErrors(data) {
		if ('value' in data) {
			this.setValue(data.value);
		}
		this.setErrors('errors' in data ? data.errors : []);
	}


	setErrors(errors = []) {
		return this.setOwnErrors(errors);
	}


	setOwnErrors(errors = []) {
		console.log('setting own errors', errors);
		// this.classList(this.element).toggle(this.invalidClass, errors.length > 0);
		// this.errorsElement.innerHTML = this.template.loop(errors, (error) => this.template.render(this.errorTpl, error));
		// return this;
	}


	// methods to be overriden

	writeValue(value) {}

	readValue() {}

};


export default baseFieldMixin;

import {html, nothing} from '../../../../../common/tpl/tpl';
import {classes, dataAttr, once, text, when} from '../../../../../common/tpl/directives';
import icon from '../../media/icon';
import actionTpl from '../../action/action';


export default (field) => {
	if ('label' in field) {
		const labelClasses = ['field__label', 'field__label--' + (field.required ? 'required' : 'optional'), (field.filled ? 'field__label--filled' : '')];
		const baseContent = () => html`
			<span class=${classes('field__labelValue')}>
				${field.label}
				${when(field.required, () => html `
					<span class=${classes('field__labelRequiredIcon', 'field__labelRequiredIcon--empty')}>${icon('empty')}</span>
					<span class=${classes('field__labelRequiredIcon', 'field__labelRequiredIcon--filled')}>${icon('done')}</span>
				`)}
			</span>
			<span class=${classes('field__labelRequired')}>${text.get('form/required')}</span>
			<span class=${classes('field__labelOptional')}>${text.get('form/optional')}</span>
			${'labelAction' in field ? actionTpl(field.labelAction): nothing}
		`;
		let content = baseContent;
		if ((field.type === 'group' || field.type === 'array') && 'collapsable' in field && field.collapsable) {
			const data = {};
			data.component = 'Toggler';
			data.dynamicTarget = true;
			data.for = field.id;
			const togglerClasses = ['field__toggler'];
			if ('collpased' in field && field.collapsed) {
				togglerClasses.push('js-toggled');
			}
			content = () => html`
				<button type="button" data=${dataAttr(data)} class=${classes(togglerClasses)}>
					${baseContent()}
					<span class=${classes('field__togglerIcon')}>${icon('right')}</span>
				</button>`;
		}
		if ((field.type === 'group' || field.type === 'array') && 'resettable' in field && field.resettable) {
			content = () => html`
				${baseContent()}
				<button class=${classes('field__resetButton', 'button', 'button--seamless')} data=${dataAttr({component: 'FieldResetAction', for: field.name})}>
					<span class=${classes('button__icon')}>${icon('reset')}</span>
					<span class=${classes('button__label')}>${text.get('form/reset')}</span>
				</button>`;
			labelClasses.push('field__label--withReset');
		}

		return html`
			<label class=${classes(labelClasses)} for=${once(field.id)}>
				${content()}
			</label>`;
	}
	return html``;
};

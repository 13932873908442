import {gsap} from 'gsap';
import {ScrollToPlugin} from 'gsap/dist/ScrollToPlugin';
// import {getScrollTop} from '../../common/utils/get-scroll';
import PageComponent from '../../common/component/page-component';

gsap.registerPlugin(ScrollToPlugin);


class ContentOverlayController extends PageComponent {

	constructor({
		root,
		element,
		visibleClass = 'visible',
		wrapperAttribute = 'contentWrapper',
		closingButtonAttribute = 'closingButton',
		modalContentAttribute = 'modalContent',
		options = {
			focus: true
		}
	}) {
		super({root: root, element: element});
		this.defaults.visibleClass = visibleClass;
		this.defaults.wrapperAttribute = wrapperAttribute;
		this.defaults.scroll = scroll;
		this.modalContentAttribute = modalContentAttribute;

		this.closingButtonAttribute = closingButtonAttribute;
		this.defaults.options = options;
		this.activated = false;
		this.wrapper = null;
		this.busy = false;
		this.modalContent = null;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		this.options = data.options;
		this.beforeToggleEvent = data.beforeToggleEvent;
		if (data.wrapperAttribute in data) {
			this.wrapper = this.root.querySelector(this.dataSelector('id', data.wrapperAttribute));
		}
		if (!this.wrapper) {
			this.wrapper = this.element.parentNode;
		}

		this.modalContent = this.root.querySelector(this.dataSelector(this.modalContentAttribute));
		this.closingButtonNode = this.element.querySelector(this.dataSelector(this.closingButtonAttribute));
		this.listeners.closingClick = this.events.on(this.closingButtonNode, 'click', this.onCloseClick.bind(this));
	}


	onCloseClick(event) {
		if (this.contexts.getCurrentContext().name === 'modal') {
			this.contexts.pop();
		}
	}


	toggle(value = null, options = {}) {
		if (this.busy) {
			return Promise.resolve();
		}

		const data = this.dataAttr().getAll();
		this.busy = true;

		options = Object.assign({}, this.options, options);

		return Promise.all(this.loadImage()).then(() => {
			const visibleClass = data.visibleClass;
			const overlayContentClone = this.element.cloneNode(true);
			const closeButtonContentNode = this.closingButtonNode.cloneNode(true);
			this.modalContent.innerHTML = '';
			this.modalContent.appendChild(closeButtonContentNode);
			this.modalContent.appendChild(overlayContentClone);
			this.classList(overlayContentClone).add(visibleClass);
			this.contexts.push('modal');

			this.element.setAttribute('aria-hidden', false);
			this.busy = false;
		});
	}

	loadImage() {
		const promises = [];
		const imgs = this.element.querySelectorAll('img');
		for (const img of imgs) {
			const AsyncPictureComponent = this.getComponent(img, 'AsyncPicture');
			promises.push(AsyncPictureComponent.load());
		}
		return promises;
	}


	isBusy() {
		return this.busy;
	}
}


export default ContentOverlayController;

import PageComponent from '../../common/component/page-component';
import {ucFirst} from '../../common/utils/string';

class DynamicTemplate extends PageComponent {

	constructor({
		root,
		element,
		renderer,
		template,
		layoutModifier = 'layout-alternate',
	}) {
		super({root: root, element: element});
		this.template = template;
		this.renderer = renderer;
		this.key = '';
		this.layoutModifier = layoutModifier;
	}


	injectBlueprints(blueprints) {
		this.blueprints = blueprints;
	}

	injectContentFactory(contentFactory) {
		this.contentFactory = contentFactory;
	}

	injectRemoteApiRepository(remoteApiRespository) {
		this.remoteApiRespository = remoteApiRespository;
	}

	async prepare() {
		this.baseUrl = this.dataAttr().get('baseUrl');
		await this.initData();

		const blueprints = await this.getBlueprints();
		const contentSelector = this.dataAttr().get('content-selector');

		this.contents = new Map();

		// lookup in the blueprints to get sections
		for (const name in blueprints.contents) {
			if (blueprints.contents.hasOwnProperty(name)) {

				const contentName = ucFirst(name);
				if (this.contentFactory.has(contentName)) {
					this.contents.set(name, this.contentFactory.newInstance('artworks', {dynamicTemplate: this, name: name}));
				} else if (this.contentFactory.has('Default')) {
					this.contents.set(name, this.contentFactory.newInstance('Default', {dynamicTemplate: this, name: name}));
				} else {
					throw Error('Default section not defined');
				}
				this.contents.get(name).init();
			}
		}
		const content = this.contents.get(contentSelector);

		if (content && content.canActivate()) {
			await content.activate();
			await this.render();
		}
	}

	async initData() {}



	async render() {
		const blueprints = await this.getBlueprints();
		this.renderer(this.template(blueprints), this.element);
	}


	async updateItemsBlueprint(items, filterData) {
		this.updateForm(filterData);
		const resultListBlueprint = await this.getBlockBlueprint('resultList');
		resultListBlueprint.items = items;
		resultListBlueprint.initial = false;
		resultListBlueprint.loading = false;
		// this.updateResultListState(false, false);

		await this.render();
	}

	async updateForm(filterData) {
		const filtersBlueprints = await this.getBlockBlueprint('filters');
		for (const field of filtersBlueprints.fields) {
			const name = field.name;

			if (name === 'artists') {
				field.fieldData.value = filterData.artistId;
			}
		}
	}



	async updatePaginationBlueprint(paginationList) {
		const resultListBlueprint = await this.getBlockBlueprint('resultList');
		resultListBlueprint.pagination = paginationList;
	}


	async updateResultListLayout(layout) {
		const resultListNode = this.element.querySelector(this.dataSelector('resultList'));
		const resultListBlueprint = await this.getBlockBlueprint('resultList');
		resultListBlueprint.layout = layout;

		const resultListClassList = this.classList(resultListNode);

		if (!resultListClassList.contains(this.layoutModifier)) {
			resultListClassList.add(this.layoutModifier);
		} else {
			resultListClassList.remove(this.layoutModifier);
		}
	}

	async updateResultListState(initial, loading) {
		const resultListBlueprint = await this.getBlockBlueprint('resultList');
		resultListBlueprint.initial = initial;
		resultListBlueprint.loading = loading;
		await this.render();
	}


	async getBlockBlueprint(blockName) {
		const blueprints = await this.getBlueprints();
		const blocks = blueprints.contents.artworks.blocks;
		let blockBlueprint = {};

		blocks.forEach((block) => {
			if (block.name === blockName) {
				blockBlueprint = block.data;
			}
		});
		return blockBlueprint;
	}


	async getBlueprints() {
		return await this.blueprints.get();
	}
}


export default DynamicTemplate;

import {html} from '../../../../common/tpl/tpl';
import {isString} from '../../../../common/utils/types';
import {dataAttr, iconInfo, unsafeSvg} from '../../../../common/tpl/directives';

export default (id, file, data = {}) => {
	if (!isString(file)) {
		data = (file ? file : {});
		file = undefined;
	}
	const info = iconInfo(id, file);
	data.type = 'svg';
	return html`
		<span data=${dataAttr(data)}>
			<canvas width=${info.width} height=${info.height}></canvas>
			<svg viewBox=${info.viewBox} focusable="false">${unsafeSvg(`<use xlink:href="${info.url}"></use>`)}</svg>
		</span>`;
};

import ModalContext from './modal-context';


class MenuContext extends ModalContext {
	constructor({name, root, popOnKey = 'esc'}) {
		super({name: name, root: root, popOnKey: popOnKey});
	}

	switchOut() {

		const MainMenuComponent = this.components.queryComponent(this.root, this.dataSelector(this.mainMenuAttribute));
		MainMenuComponent.resetMenu();
		super.switchOut();
	}

	switchIn() {
		const MainMenuComponent = this.components.queryComponent(this.root, this.dataSelector(this.mainMenuAttribute));
		const headerNode = this.root.querySelector(this.dataSelector(this.headerAttribute));

		this.onTransitionEnd(headerNode).then(() => {
			MainMenuComponent.setMainMenuPosition();
		});

		super.switchIn();
	}
}


export default MenuContext;

import PageComponent from '../../common/component/page-component';


class UserGeneratedZoomImage extends PageComponent {

	constructor({root, element, modalContentAttribute = 'modalContent', activeClass = 'active'}) {
		super({root: root, element: element});
		this.modalContentAttribute = modalContentAttribute;
		this.activeClass = activeClass;
		this.firstInteraction = true;
		this.selected = false;
	}

	prepare() {
		const img = this.element.querySelector('img');
		const figcaption = this.element.querySelector('figcaption');
		const largeImgSrc = this.element.getAttribute('href');
		this.zoomedItem = this.getZoomedItem(img, largeImgSrc, (figcaption ? figcaption.innerHTML : ''));
		this.zoomedLargeImage = this.getComponent(this.zoomedItem.querySelector(this.dataSelector('largeImage')));
		const modalContent = this.root.querySelector(this.dataSelector(this.modalContentAttribute));
		modalContent.appendChild(this.zoomedItem);
		this.listeners.click = this.events.on(this.element, 'click', this.onClick.bind(this));
		this.listeners.context = this.events.on(this.root, 'context:switchcomplete', this.onContextSwitch.bind(this));
	}


	onClick(event, target) {
		event.preventDefault();
		target.blur();
		const enabled = (getComputedStyle(target).pointerEvents !== 'none');
		if (enabled) {
			if (!this.selected) {
				this.selected = true;
				if (this.firstInteraction) {
					this.firstInteraction = false;
					this.zoomedLargeImage.load();
				}
				this.classList(this.zoomedItem).add(this.activeClass);
				this.contexts.push('modal');
			}
		}
	}


	onContextSwitch(event) {
		if (event.detail.previousContext.getName() === 'modal' && this.selected) {
			this.classList(this.zoomedItem).remove(this.activeClass);
			this.selected = false;
		}
	}


	getZoomedItem(smallImg, largeImgSrc, caption = '') {
		smallImg = smallImg.cloneNode();
		const largeImg = smallImg.cloneNode();

		smallImg.setAttribute('class', this.classListParser.buildBaseString(['modalItem__image', 'modalItem__image--small']));
		largeImg.setAttribute('class', this.classListParser.buildBaseString(['modalItem__image', 'modalItem__image--large']));
		if (largeImg.hasAttribute('srcset')) {
			largeImg.removeAttribute('srcset');
		}
		if (largeImg.hasAttribute('sizes')) {
			largeImg.removeAttribute('sizes');
		}
		const data = this.dataAttr(largeImg);
		data.set('autoload', false);
		data.set('srcset', largeImgSrc);
		data.set('largeImage', true);

		const zoomedItem = this.prepareZoomedItem(caption);
		const imagesContainer = zoomedItem.querySelector(this.dataSelector('modalItemImages'));
		imagesContainer.appendChild(smallImg);
		imagesContainer.appendChild(largeImg);
		return zoomedItem;
		// <img class="ubf-picture__img ubf-js-loaded" draggable="false" alt="" src="data:image/gif;base64,R0lGODlhAQABAAAAADs=" data-ubf-autoload="true" data-ubf-component="AsyncPicture" srcset="/ubf/dist/cache/thumbs/media/img/DSC06968-portrait-20181215161626.320-0-85.jpg 320w, /ubf/dist/cache/thumbs/media/img/DSC06968-portrait-20181215161626.512-0-85.jpg 512w, /ubf/dist/cache/thumbs/media/img/DSC06968-portrait-20181215161626.768-0-85.jpg 768w, /ubf/dist/cache/thumbs/media/img/DSC06968-portrait-20181215161626.1024-0-85.jpg 1024w, /ubf/dist/cache/thumbs/media/img/DSC06968-portrait-20181215161626.1280-0-85.jpg 1280w, /ubf/dist/cache/thumbs/media/img/DSC06968-portrait-20181215161626.1400-0-85.jpg 1400w, /ubf/dist/cache/thumbs/media/img/DSC06968-portrait-20181215161626.1600-0-85.jpg 1600w, /ubf/dist/cache/thumbs/media/img/DSC06968-portrait-20181215161626.2048-0-85.jpg 2048w, /ubf/dist/cache/thumbs/media/img/DSC06968-portrait-20181215161626.2500-0-85.jpg 2500w" sizes="(min-width: 1024px) 375px, 98vw">

		// return this.parseTemplate();
	}


	prepareZoomedItem(caption = '') {
		const tpl = `
			<div class="${this.classListParser.buildBaseString(['modalItem__images'])}" ${this.dataAttrParser.buildString('modalItemImages', '')}>
			</div>
			${caption.length ? `
				<div class="${this.classListParser.buildBaseString(['modalItem__caption'])}">
					${caption}
				</div>
			` : ''}
		`;
		const div = document.createElement('div');
		div.innerHTML = tpl;
		div.setAttribute('class', this.classListParser.buildBaseString(['modal__item', 'modalItem']));
		return div;
	}

}


export default UserGeneratedZoomImage;

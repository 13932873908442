const classes = (...names) => {
	if (names.length === 1 && Array.isArray(names[0])) {
		names = names[0];
	}

	// return names.map((name) => (name.split(/\s+/).map((subName) => classes.ns + subName).join(' '))).join(' ');
	// loop are usually faster
	const newNames = [];
	for (let i = 0; i < names.length; i++) {
		const subNames = names[i].split(/\s+/);
		for (let j = 0; j < subNames.length; j++) {
			if (subNames[j].length) {
				newNames[newNames.length] = classes.ns + subNames[j];
			}
		}
	}
	return newNames.join(' ');
};

classes.ns = '';


export default classes;

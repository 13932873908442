import pagination from '../pagination';

export default async (remoteApiRepository) => {
	const items = [];

	const blueprint = {
		remoteApiRepository: remoteApiRepository,
		items: items,
		initial: true,
		loading: false,
		layout: 'list',
		pagination: await pagination(),
	};

	return blueprint;
};


import {html, nothing} from '../../../../../common/tpl/tpl';
import {classes, dataAttr, attr, once} from '../../../../../common/tpl/directives';


export default (field) => html`
		<input type="checkbox"
			name=${once(field.inputName)}
			id=${once(field.id)}
			class=${classes('field__input', 'field__input--checkbox')}
			data=${dataAttr('data' in field ? field.data : {})}
			attr=${attr(Object.assign({}, field.attributes, {'.disabled': field.disabled, '.checked': !!(+field.value)}))} />
		<label class=${classes('field__label')} for=${once(field.id)}>
			<span class=${classes('field__labelValue')}>${'label' in field ? field.label : nothing}</span>
		</label>`
;


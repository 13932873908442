import PageComponent from '../component/page-component';
import baseFieldMixin from './base-field-mixin';
import fieldsGroupMixin from './fields-group-mixin';
// import discoverFieldsMixin from './discover-fields-mixin';
// import formConstants from './_constants';


class GroupField extends fieldsGroupMixin(baseFieldMixin((PageComponent))) {

	constructor({
		root,
		element,
		autoAddFields = true,
		invalidClass = 'invalid',
		errorTpl
	}) {
		super({root: root, element: element});
		this.autoAddFields = autoAddFields;
		this.invalidClass = invalidClass;
		this.errorTpl = errorTpl;
	}

}


export default GroupField;

import DynamicSection from './dynamic-section';

// SECTIONS
import DynamicSectionDefaultContent from './contents/default';
import DynamicSectionArtworksContent from './contents/artworks';
import FilterForm from './filter-form';
import FilterSearchField from './fields/filter-search-field';
import FilterNumberField from './fields/filter-number-field';

import template from './templates/main';
import blueprints from './blueprints/index';

export default (di) => {
	di
		.setType({
			type: DynamicSection,
			name: 'DynamicSection',
			parent: 'DynamicTemplate',
			params: {
				template: template
			},
			setters: {
				injectContentFactory: di.lazyNew('Factory', {prefix: 'DynamicSection', suffix: 'Content'}),
				injectBlueprints: di.lazyNew('Blueprints', {blueprints: blueprints, remoteApiRepository: di.lazyGet('templateController/remoteApiRepository')}),
				injectPaginationItems: di.lazyGet('pagination/items'),

			}
		})

		.setType({
			type: DynamicSectionDefaultContent,
			name: 'DynamicSectionDefaultContent',
			parent: 'DynamicTemplateSection'
		})
		.setType({
			type: DynamicSectionArtworksContent,
			name: 'DynamicSectionArtworksContent',
			parent: 'DynamicSectionDefaultContent',
			setters: {
				injectRemoteApiRepository: di.lazyGet('templateController/remoteApiRepository'),
			}
		})

		.setType({
			type: FilterForm,
			name: 'FilterForm',
			parent: 'PageComponent',
			setters: {
				injectRemoteApiRepository: di.lazyGet('templateController/remoteApiRepository'),
			}
		})
		.setType({
			type: FilterSearchField,
			name: 'FilterSearchField',
			parent: 'SearchField',
		})
		.setType({
			type: FilterNumberField,
			name: 'FilterNumberField',
			parent: 'TextField',
		})
	;
};
